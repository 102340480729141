import request from "../utils/request";

export default class BaseAPI {
    constructor(){

    }

    async get(url, params, headers){
        return await request(url, {
            method:"get",
            params:params,
            headers
        })
    }

    async post(url, data, headers){
        return await request(url,{
            method:"post",
            data, headers
        })
    }
}
