/**
 *
 * ClassInstruction
 *
 */

import React, { memo } from "react";
import {
  Box,
  Card,
} from "@material-ui/core";
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

function ColumnInsights(props) {
  const numberWithCommas = (x) => {
    return x? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : x;
  }

  return (
    <div>
      <Card
        elevation={4}
        style={{ borderRadius: 10 }}
        className="flex flex-row h-96 w-56 justify-center pb-4"
      > {props.columnInsigntData ?
        (<Box className="flex flex-col" alignItems="center" textAlign="center">
          <Box
            className="flex flex-row my-4 "
            style={{ fontSize: "16px" }}
          >
            <Tooltip title="Select a feature to see the feature's details" placement="right">
              <div>
                <h1 className=" text-lg font-bold ">Feature Insights</h1>
                <p>({props.columnInsigntData && props.columnInsigntData.feature_name})</p>
              </div>
            </Tooltip>
          </Box>

          <Box className="flex flex-row">
          </Box>
          <TableContainer >
            <Table aria-label="Column insights table">
              <TableBody>
                <TableRow style={{ height: 50 }} className="p-12">
                  <TableCell className="h-12 w-[50%]">Unique Values</TableCell>
                  <TableCell className="h-12 w-[50%]" align="right">{numberWithCommas(props.columnInsigntData && props.columnInsigntData.unique_cnt)}</TableCell>
                </TableRow >
                <TableRow className="h-2" style={{ height: 10 }}>
                  <TableCell className="w-[50%]">Null Count</TableCell>
                  <TableCell className="w-[50%]" align="right" style={{ height: 'auto !important' }}>{numberWithCommas(props.columnInsigntData && props.columnInsigntData.null_cnt)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="w-[50%]">Non Null Count</TableCell>
                  <TableCell className="w-[50%]" align="right">{numberWithCommas(props.columnInsigntData && props.columnInsigntData.non_null_cnt)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="w-[50%]">Null % </TableCell>
                  <TableCell className="w-[50%]" align="right">{numberWithCommas(parseInt(props.columnInsigntData && props.columnInsigntData?.null_perct)?.toFixed(2))} %</TableCell>
                </TableRow>
                {
                  props.columnInsigntData?.drvd_data_type === "categorical" ? (
                    <>
                      <TableRow>
                        <TableCell className="w-[50%]">Top Value </TableCell>
                        <TableCell className="w-[50%]" align="right">{numberWithCommas(props.columnInsigntData && props.columnInsigntData.most_recurring)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="w-[50%]">Top Value Frequency </TableCell>
                        <TableCell className="w-[50%]" align="right">{numberWithCommas(props.columnInsigntData && props.columnInsigntData.frequency)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="w-[50%]">Second Top Value </TableCell>
                        <TableCell className="w-[50%]" align="right">{numberWithCommas(props.columnInsigntData && props.columnInsigntData.sec_recurring)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="w-[50%]">Second Top Value Frequency</TableCell>
                        <TableCell className="w-[50%]" align="right">{numberWithCommas(props.columnInsigntData && props.columnInsigntData.sec_frequency)}</TableCell>
                      </TableRow>
                    </>
                  ) : (props.columnInsigntData?.drvd_data_type === "numerical" ? (
                    <>
                      <TableRow>
                        <TableCell className="w-[50%]">Min Value </TableCell>
                        <TableCell className="w-[50%]" align="right">{numberWithCommas(props.columnInsigntData && props.columnInsigntData?.minimum?.toFixed(2))}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="w-[50%]">Max Value </TableCell>
                        <TableCell className="w-[50%]" align="right">{numberWithCommas(props.columnInsigntData && props.columnInsigntData?.maximum?.toFixed(2))}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="w-[50%]">Mean </TableCell>
                        <TableCell className="w-[50%]" align="right">{numberWithCommas(parseFloat(props.columnInsigntData && props.columnInsigntData?.mean)?.toFixed(2))}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="w-[50%]">Median</TableCell>
                        <TableCell className="w-[50%]" align="right">{numberWithCommas(parseFloat(props.columnInsigntData && props.columnInsigntData?.median)?.toFixed(2))}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="w-[50%]">Mode</TableCell>
                        <TableCell className="w-[50%]" align="right">{numberWithCommas(parseFloat(props.columnInsigntData && props.columnInsigntData?.mode)?.toFixed(2))}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="w-[50%]">Percentile 25% </TableCell>
                        <TableCell className="w-[50%]" align="right">{numberWithCommas(props.columnInsigntData && parseFloat(props.columnInsigntData.perct_25)?.toFixed(2))}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="w-[50%]">Percentile 50% </TableCell>
                        <TableCell className="w-[50%]" align="right">{numberWithCommas(props.columnInsigntData && parseFloat(props.columnInsigntData.perct_50).toFixed(2))}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="w-[50%]">Percentile 75% </TableCell>
                        <TableCell className="w-[50%]" align="right">{numberWithCommas(props.columnInsigntData && parseFloat(props.columnInsigntData.perct_75).toFixed(2))}</TableCell>
                      </TableRow>
                    </>
                  ) : (<>
                    <TableRow xs={12} md={12}>
                      <TableCell className="w-[50%]">Top Value </TableCell>
                      <TableCell className="w-[50%]" align="right">{props.columnInsigntData && props.columnInsigntData.most_recurring}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="w-0.5">Top Value Frequency </TableCell>
                      <TableCell className="w-[50%]" align="right">{props.columnInsigntData && props.columnInsigntData.frequency}</TableCell>
                    </TableRow>
                  </>)

                  )
                }
              </TableBody >
            </Table >
          </TableContainer >
        </Box >) : (
          <Box className="flex p-5 items-center justify-center" >
            <CircularProgress size="2rem" />
          </Box>
        )
        }
      </Card >
    </div >
  );
}

ColumnInsights.propTypes = {};

export default memo(ColumnInsights);
