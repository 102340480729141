/**
 *
 * DataSource
 *
 */

import React, { useState, useEffect, useContext } from "react";
import AppContext from '../../components/AppContext/AppContext';
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import {
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  MenuItem,
  FormControl,
  Select,
} from "@material-ui/core";
import { HiArrowCircleLeft } from 'react-icons/hi';
import "./dataSource.css";
import { NavLink, useNavigate } from "react-router-dom";
import ColumnInsights from "./ColumnInsights.js";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import HorizontalLabelPositionBelowStepper from "../../components/HorizontalStepperComponent";
import Grid from '@mui/material/Grid';
import { DataGrid } from "@material-ui/data-grid";
import Tooltip from '@mui/material/Tooltip';
import Skeleton from '@mui/material/Skeleton';
import { Card } from "@material-ui/core";
import { EdaAPI } from "../../apis/eda.api";
import { PRIMARY_COLOR } from "../../constants"
import { BsFillQuestionCircleFill } from 'react-icons/bs'
import IconButton from '@mui/material/IconButton';
import { handleApiCall } from "../../utils/request"

function DataSource() {
  // below are the variables
  const navigate = useNavigate();
  const myContext = useContext(AppContext);
  const run_id = myContext.run_id;
  const data_source = myContext.data_source;
  const baseURL = process.env.REACT_APP_API_HOST;

  console.log("get details...." + run_id + "..." + data_source + "...")
  const [sampleData, setSampleData] = React.useState([]);
  const [sampleColumns, setSampleColumns] = React.useState([]);

  // const [rowFilter, setRowFilter] = useState('');
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [sourceDetails, setSourceDetails] = useState({})
  const [formData, setFormData] = useState([]);
  const [featureDatatypeMapping, setFeatureDatatypeMapping] = useState();

  const handleRowSelection = (selectedRows) => {
    if (selectedRows) {
      setSelectedRow(selectedRows[0]);
    }
  };

  const handleFormDataChange = (newFormData) => {
    console.log("handleFormDataChange check", newFormData);
    if (newFormData) {
      setFormData(newFormData);
      console.log(formData, "selectedRow check 2 formData")
    }
  };

  function duplicateKey(obj, oldKey, duplicateKey) {
    obj[duplicateKey] = obj[oldKey];
  };

  const fetchTableDetails = async () => {
    console.log("myContext.source_details", myContext.source_details)
    if (Object.keys(myContext.source_details).length) {
      setSourceDetails(myContext.source_details)
      const response = await handleApiCall(`${baseURL}edaRun/${run_id}`, 'get', {}, navigate, myContext)
      if (response?.status == 200) {
        console.log(response);
        console.log("source_details", sourceDetails);
        var data = response.data;
        var featureTypeMap = {}
        data.feature_details.forEach(obj => {
          duplicateKey(obj, 'feature_sl_no', 'id')
          featureTypeMap[obj['feature_name']] = obj['bq_data_type']
        });
        setFeatureDatatypeMapping(featureTypeMap)
        setFormData(data?.feature_details)
        setSelectedRow(data?.feature_details[0])
      } else {
        console.log(response);
      }
    } else {
      const res = EdaAPI.getTableDetails(data_source, run_id, navigate, myContext);
      res.then((response) => {
        console.log("INSIDE RESPONSE", response)
        var data = response.data;
        console.log("TABLE DATA", data)
        data.table_schema.forEach(obj => duplicateKey(obj, 'feature_sl_no', 'id'));
        setFormData(data.table_schema);
        delete data.table_schema
        setSourceDetails(data)
      }).then(async () => {
        const response = await handleApiCall(`${baseURL}getColumnInsights?run_id=${run_id}`, 'get', {}, navigate, myContext)
        if (response?.status == 200) {
          console.log("INSIDE RESPONSE", response)
          var data = response.data;
          var featureTypeMap = {}
          data.feature_details.forEach(obj => {
            duplicateKey(obj, 'feature_sl_no', 'id')
            featureTypeMap[obj['feature_name']] = obj['bq_data_type']
          });
          setFeatureDatatypeMapping(featureTypeMap)
          console.log("FEATURES DATA", data)
          setFormData(data.feature_details);
          setSelectedRow(data.feature_details[0])

          // data.table_schema.forEach(obj => duplicateKey(obj, 'feature_sl_no', 'id'));
          // setFormData(data.table_schema);
          // delete data.table_schema
          // setSourceDetails(data)
        }
      });
    }
  };

  function uniqueKeyGenerator(arr) {
    for (var i = 0; i < arr.length; i++) {
      arr[i].id = i + 1;
    }
    return arr
  };

  const fetchSampleData = async () => {
    const res = EdaAPI.getSampleRecords(data_source, run_id, navigate, myContext);
    res.then((response) => {
      var data = response.data;
      const sampleData = uniqueKeyGenerator(data)
      setSampleData(sampleData)
      setSampleColumns(Object.keys(data[0]).map(x => ({
        field: x, headerName: x, flex: 1, "minWidth": 180, renderCell: (params) => { return ((!isNaN(params.row[x]) && params.row[x] > 999 && (featureDatatypeMapping[x]==="INT64" || featureDatatypeMapping[x]==="FLOAT64")) ? numberWithCommas(params.row[x]) : params.row[x]); }
      })))
    });
  };

  useEffect(() => {
    if (run_id === "") {
      // || data_source === ""
      console.log("values are empty")
      navigate('/dashboard');
    }
    fetchTableDetails();
    return () => { };
  }, []);

  useEffect(() => {
    if (run_id === "") {
      console.log("values are empty")
      navigate('/dashboard');
    }
    if(featureDatatypeMapping) fetchSampleData();
    return () => { };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureDatatypeMapping]);

  const updateEDARunState = async () => {
    // Selecting only required fields
    const feature_details = formData.map(
      ({ id, feature_sl_no, feature_name, bq_data_type, drvd_data_type, fnl_data_type, is_input, non_null_cnt, null_cnt, null_perct, unique_cnt, row_cnt, mean, std, minimum, perct_25, perct_50, perct_75, maximum, most_recurring, frequency, sec_recurring, sec_frequency, is_output }) =>
        ({ id, feature_sl_no, feature_name, bq_data_type, drvd_data_type, fnl_data_type, is_input, non_null_cnt, null_cnt, null_perct, unique_cnt, row_cnt, mean, std, minimum, perct_25, perct_50, perct_75, maximum, most_recurring, frequency, sec_recurring, sec_frequency, is_output }))
    // Updating context
    myContext.setSelectedFeatures(feature_details)
    myContext.setSourceDetails(sourceDetails)
    const payload = { "feature_details": feature_details }
    const response = await handleApiCall(`${baseURL}edaRun/${run_id}`, 'put', payload, navigate, myContext)
    if (response?.status == 200) {
      console.log(response);
      console.log("saved intermediate data")
    } else {
      console.log(response);
    }
  };

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div className="md:px-[15%] px-[5%]" >
      <Helmet>
        <title>Dashboard</title>
        <meta name="description" content="Description of Dashboard" />
      </Helmet>

      <Box className="container-bg bg-contain">
        <Grid container className="m-auto py-5 md:pb-5 md:p-2  pt-5 md:pt-8">
          <Grid item md={12} xs={12} className="flex flex-row items-center justify-center mb-8">
            <Tooltip title="Move back to Dashboard page" placement="top">
              <NavLink to="/dashboard">
                <span><HiArrowCircleLeft onClick={(event) => { updateEDARunState() }} style={{ width: '66px', height: '66px', color: PRIMARY_COLOR }} /></span>
              </NavLink>
            </Tooltip>
            <HorizontalLabelPositionBelowStepper xs={6} activeStp={0} />
            <Tooltip title="Move to Cleansing Page" placement="top">
              <NavLink to="/data-cleansing">
                <span><HiArrowCircleLeft onClick={(event) => { updateEDARunState() }} className="flip-image" style={{ width: '66px', height: '66px', color: PRIMARY_COLOR }} /></span>
              </NavLink>
            </Tooltip>
          </Grid>
          <Grid item md={12} className="w-full">
            <Card elevation={4} style={{ borderRadius: 10 }} className="mb-4 px-8 w-full" >
              <Grid container className="flex flex-col">
                <Grid xs={12} className="my-2 text-blue-500 text-2xl">
                  <b> Source Detail </b>{" "}
                </Grid>
                {sourceDetails?.features_cnt ?
                  (<Grid container className="my-2">
                    <Grid md={12} xs={12} className="md:mb-5">
                      <b> Table ID : </b>
                      {data_source}
                    </Grid>
                    <Grid md={4} xs={12} >
                      {" "}
                      <b> Features Count </b>: {numberWithCommas(sourceDetails?.features_cnt)}
                      {" "}
                    </Grid>
                    <Grid md={4} xs={12} >
                      {" "}
                      <b>Table size(bytes) </b> : {numberWithCommas(sourceDetails?.data_size_bytes)}
                    </Grid>
                    <Grid md={4} xs={12}>
                      {" "}
                      <b> Number of rows </b> : {numberWithCommas(sourceDetails?.rows_cnt)}
                    </Grid>
                  </Grid>) :
                  (<Box className="flex flex-col">
                    <Box className="mb-5">
                      <Skeleton sx={{ width: 300 }} />
                    </Box>
                    <Box>
                      <Skeleton animation="wave" />
                    </Box>
                  </Box>)
                }
              </Grid>
            </Card>
          </Grid>
          <Grid item md={12} className="pb-4">
            <Card elevation={4} style={{ borderRadius: 10, border: '1px' }}>
              <Box className="my-4 px-10 text-blue-500 text-2xl" >
                <b>Pick Your Features </b>
                <Tooltip title="Select your input and output feature, also select the feature to see the deature insights" placement="bottom-start">
                  <IconButton>
                    <BsFillQuestionCircleFill />
                  </IconButton>
                </Tooltip>
              </Box>
              <Grid container className="flex flex-row mx-10 mb-8" style={{ justifyContent: "stretch", alignItems: "stretch" }}>
                <Grid md={9} xs={12}>
                  <DataSourceTable
                    onSelection={handleRowSelection}
                    formData={formData?.table_schema ? formData.table_schema : formData.feature_details ? formData.feature_details : formData}  //sourceData.table_schema
                    onChangeFormData={handleFormDataChange}
                  />
                </Grid>

                <Grid md={3} xs={12} className="flex flex-col w-1/4 pl-4 pr-0">
                  <ColumnInsights
                    selectedRowId={selectedRow}
                    columnInsigntData={selectedRow}
                    className="pl-5"
                  />
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item md={12}>
            <Card elevation={4} style={{ borderRadius: 10, border: '1px' }}>
              <Box className="my-4 px-10 text-blue-500 text-2xl">
                <b>Sample Data </b>
                <Tooltip title="Below table shows 5 sample rows from the raw data" placement="bottom-start">
                  <IconButton>
                    <BsFillQuestionCircleFill />
                  </IconButton>
                </Tooltip>

              </Box>
              <Box className="w-6/6 mx-10 mb-10" style={{ height: 400 }}>
                <DataGrid
                  id={Math.random()}
                  rows={sampleData}
                  columns={sampleColumns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  checkboxSelection={false}
                  loading={!featureDatatypeMapping || sampleData.length === 0}
                />
              </Box>
            </Card>
          </Grid>
          {/* <Box style={{border:'1px solid rgba(0, 0, 0, 0.54)'}} className='pb-3'>
            <Box className="w-11/12 m-auto ">
                <form
                  method="POST"
                  encType="multipart/form-data"
                  className="self-center"
                  // onSubmit={handleSubmit}
                  id="productForm"
                >
                  <Box className="flex flex-col w-2/5">
                    <Box className="bg-white font-semi-bold mt-5 ml-5">
                      Filter your Data
                    </Box>
                    <Box className=" mt-2 ml-5">
                      <TextField
                        id="name"
                        label="Row Filter"
                        type="text"
                        required
                        // className={classes.textField}
                        // onChange={onChange}
                        size="small"
                      />
                    </Box>
                  </Box>
                </form>
            </Box>
        </Box> */}
        </Grid>

      </Box>
      {/* Back & Next Buttons */}
      <Box className="flex flex-row items-center justify-center my-10 ">
        <Box className=" mt-4 pr-20  ">
          <NavLink to="/dashboard">
            <Button variant="contained" disableElevation style={{ backgroundColor: PRIMARY_COLOR, color: "white" }} onClick={(event) => { updateEDARunState() }}>
              <span className="w-full py-1 px-5">Back</span>
            </Button>
          </NavLink>
        </Box>
        <Box className=" mt-4 pr-20">
          <NavLink to="/data-cleansing">
            <Button
              variant="contained"
              disableElevation
              style={{ backgroundColor: PRIMARY_COLOR, color: "white" }}
              onClick={(event) => {
                updateEDARunState();
              }}
            >
              <span className="w-full py-1 px-5">Next </span>
            </Button>
          </NavLink>
        </Box>
      </Box>
    </div >
  );
}

export default DataSource;


// Features Table Component
function DataSourceTable(props) {
  const [selectionModel, setSelectionModel] = React.useState([1]);
  const handleSelectedRow = (newSelection) => {
    if (newSelection?.length) {
      setSelectionModel(newSelection)
      const selectedRowData = props.formData.filter((row) => row.id === newSelection[0]);
      props.onSelection(selectedRowData);

    }
  };

  const handleDataType = (id, datatype) => {
    console.log("handleDataType", id, datatype)
    const selectedRow = props.formData.filter(row => row.id == id)[0]
    const newFormData = props.formData.filter(row => row.id != id)
    selectedRow.fnl_data_type = datatype
    newFormData.push(selectedRow)
    newFormData.sort((a, b) => (a.id > b.id) ? 1 : -1)
    props.onChangeFormData(newFormData)
    console.log(props.formData, "datatype updated")
  }

  const handleInputFeatureCheck = (id, isCurrentlyChecked) => {
    console.log(id, "check handleInputFeatureCheck ");
    const selectedRow = props.formData.filter(row => row.id === id)[0]
    const newFormData = props.formData.filter(row => row.id !== id)
    if (selectedRow.is_input)
      selectedRow.is_input = 0
    else {
      selectedRow.is_input = 1
      selectedRow.is_output = 0
    }
    console.log(selectedRow, "selectedRow after testing")

    newFormData.push(selectedRow)
    newFormData.sort((a, b) => (a.id > b.id) ? 1 : -1)

    props.onChangeFormData(newFormData)
    console.log(props.formData, "formData finally 2")
  };

  const toggleCheckedOutputFeature = (id) => {
    console.log(id, "check toggleCheckedOutputFeature ");
    const selectedRow = props.formData.filter(row => row.id == id)[0]
    console.log(selectedRow, "selectedRow before testing")
    const newFormData = props.formData.filter(row => row.id != id)
    if (selectedRow.is_output)
      selectedRow.is_output = 0
    else {
      selectedRow.is_output = 1
    }
    console.log(selectedRow, "selectedRow after testing")
    newFormData.push(selectedRow)
    newFormData.sort((a, b) => (a.id > b.id) ? 1 : -1)
    // if (selectedRow.is_output) {
    //   newFormData.forEach(data => {
    //     if(data.id != id)
    //     data.is_output = false
    // });
    // }
    props.onChangeFormData(newFormData)
    console.log(props.formData, "formData finally 3")
  };

  const columns = [
    { field: "feature_sl_no", headerName: "ID", width: 170, hide: true },
    { field: "feature_name", headerName: "Feature Name", width: 170, fontWeight: "bold" },
    {
      field: "drvd_data_type",
      headerName: "Field Type",
      width: 200,
      renderCell: (params) => {
        console.log("PARAM TYPE", params)
        return (
          <FormControl required>
            <Select
              id="drvd_data_type"
              value={params.row.fnl_data_type}
              onChange={(e) => handleDataType(params.row.id, e.target.value)}
            >
              <MenuItem value={"numerical"}> NUMERICAL </MenuItem>
              <MenuItem value={"categorical"}> CATEGORIAL </MenuItem>
              <MenuItem value={"timestamp"}> TIMESTAMP </MenuItem>
              <MenuItem value={"datetime"}> DATETIME </MenuItem>
              <MenuItem value={"date"}> DATE </MenuItem>
            </Select>
          </FormControl>
        );
      },
    },
    {
      field: "is_input",
      width: 260,
      headerName: "Input Features",
      renderCell: (params) => {
        console.log(params.row.is_input, "params.row check")
        return (
          <Checkbox
            size="small"
            checked={props.formData.filter(row => row.id == params.row.id)[0].is_input}
            onChange={() => handleInputFeatureCheck(params.row.id, params.row.is_input)}
            style={{ color: PRIMARY_COLOR }}
          />
        );
      },
    },
    {
      field: "isOutput",
      width: 260,
      headerName: "Output Features",

      renderCell: (params) => {
        console.log("OUTPUT FEATURE...", params.row.id, params.row, props.formData.filter(row => row.id === params.row.id)[0].is_output)
        return (
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  value={props.formData.filter(row => row.id === params.row.id)[0].is_output}
                  checked={props.formData.filter(row => row.id === params.row.id)[0].is_output}
                  onChange={() => toggleCheckedOutputFeature(params.row.id)}
                  disabled={props.formData.filter(row => row.id === params.row.id)[0].is_input}
                // style={{ color: PRIMARY_COLOR }}
                />
              }
            />
          </FormGroup>
        );
      },
    }
  ];
  return (
    <div className="h-96">
      <DataGrid
        id={Math.random()}
        rows={props.formData}
        columns={columns}
        pageSize={5}
        checkboxSelection={false}
        rowsPerPageOptions={[5]}
        onSelectionModelChange={(newSelection) => {
          handleSelectedRow(newSelection);
        }}
        selectionModel={selectionModel}
        loading={props.formData.length === 0}
      />
    </div>
  );
}
