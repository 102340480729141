/**
 *
 * Login
 *
 */

import React, { useState, useEffect, useContext } from "react";
import AppContext from '../../components/AppContext/AppContext';
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Modal,
  TextField,
  Box,
  makeStyles,
} from "@material-ui/core";
import "./style.css";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { handleApiCall } from "../../utils/request"

import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { Card } from "@material-ui/core";
import StarIcon from '@mui/icons-material/StarBorder';
import DoneIcon from '@mui/icons-material/Done';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import { useForm, ValidationError } from '@formspree/react';
import ContactForm from "../ContactForm";
import MuiAlert from '@mui/material/Alert';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const baseURL = process.env.REACT_APP_API_HOST;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
}));

export default function Subscription() {
  const navigate = useNavigate();
  const classes = useStyles();

  const myContext = useContext(AppContext);
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false);


  const [formValues, setFormValues] = useState({
    run_name: {
      value: '',
      error: false,
      errorMessage: 'You must enter a run name'
    },
    source_url: {
      value: '',
      error: false,
      errorMessage: 'You must enter a source url'
    }
  })
  const [alert, setAlert] = React.useState({ 'open': false, 'message': '' });
  const tiers = [
    {
      title: 'Free',
      price: '0',
      description: [
        // 'Individual use only',
        // '1 user included',
        '100 MB of files can be uploaded per day per user',
        '5 EDAs can be generated per day per user'
      ],
      buttonText: 'Get Started',
      buttonLink: "signup",
      buttonVariant: 'outlined',
    },
    {
      title: 'Pro',
      // subheader: 'Most popular',
      price: '5',
      description: [
        // '20 users included',
        '10 GB of data can be uploaded per day per user',
        '100 EDAs can be generated per day per user',
        'Email support',
      ],
      buttonText: 'Subscribe',
      buttonLink: "checkout",
      buttonVariant: 'contained',
    },
    {
      title: 'Enterprise',
      // price: '30',
      description: [
        'Contact the sales team for more advanced usage.',
      ],
      buttonLink: "#",
      buttonText: 'Contact us',
      buttonVariant: 'outlined',
    },
  ];

  const handleModalClose = (open) => {
    setOpen(false);

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Your submit logic here
    handleClose();
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlert({ 'open': false, 'message': '' });
  };

  const handleSubscriptionAction = async (tier) => {
    if (tier == "Free") {
      navigate('/login');
    }
    else if (tier == "Pro") {
      setLoading(true)
      try {
        const response = await handleApiCall(`${baseURL}create-checkout-session?subcription_id=2`, 'get', {}, navigate, myContext)
        setLoading(false)
        console.log(response, response.data.url)
        window.location.href = response.data.url
      } catch (error) {
        setLoading(false)
        console.log("error...", error.response.data);
        setAlert({ 'open': true, 'message': error.response.data.message, severity: 'error' })
      }
    }
    else if (tier === "Enterprise") {
      setOpen(true)
    }
  };


  return (
    <div>
      <Snackbar className=" mt-20" anchorOrigin={{ vertical: "top", horizontal: "center" }} open={alert.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alert.severity} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
      {loading ? <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop> : ""}
      <ContactForm open={open} handleModalClose={handleModalClose} />
      <Box className="py-8 mb-4 bg-slate-100">
        <Container disableGutters maxWidth="sm" component="main" sx={{ pb: 6 }}>
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            Subscriptions
          </Typography>
          <Typography variant="h5" align="center" color="text.secondary" component="p">
            One tool for your Data Science team to make their development easier.
            Free for individuals to try.
          </Typography>
        </Container>
        <Container maxWidth="md" component="main">
          <Grid container spacing={5} alignItems="flex-end">
            {tiers.map((tier) => (
              // Enterprise card is full width at sm breakpoint
              <Grid
                item
                key={tier.title}
                xs={12}
                sm={tier.title === 'Enterprise' ? 12 : 6}
                md={4}
              >
                <Card>
                  <CardHeader
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{ align: 'center' }}
                    action={tier.title === 'Pro' ? <StarIcon /> : null}
                    subheaderTypographyProps={{
                      align: 'center',
                    }}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                          ? theme.palette.grey[200]
                          : theme.palette.grey[700],
                    }}
                  />
                  <CardContent>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'baseline',
                        mb: 2,
                      }}
                    >
                      {tier.price ? (
                        <>
                          <Typography component="h2" variant="h3" color="text.primary">
                            ${tier.price}
                          </Typography>
                          <Typography variant="h6" color="text.secondary" className="mt-8">
                            /mo
                          </Typography>
                        </>) : ""}

                    </Box>
                    <ul>
                      {tier.description.map((line) => (
                        <Typography
                          component="li"
                          variant="subtitle1"
                          // align="center"
                          key={line}
                        >
                          <DoneIcon color="disabled" className="scale-75" /> {line}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                  <CardActions>
                    <Button fullWidth variant={tier.buttonVariant} onClick={() => handleSubscriptionAction(tier.title)}>
                      {tier.buttonText}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </div >
  );
}
