/**
 *
 * Signup
 *
 */

import React, { useState, useEffect, useContext } from "react";
import AppContext from '../../components/AppContext/AppContext';
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import {
  Box,
  Button,
  TextField,
} from "@material-ui/core";
import "./style.css";
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { BsFillQuestionCircleFill } from 'react-icons/bs'

import Backdrop from '@mui/material/Backdrop';
import axios from 'axios';
import { PRIMARY_COLOR } from "../../constants";
import { Card } from "@material-ui/core";
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const baseURL = process.env.REACT_APP_API_HOST;
const baseauthURL = process.env.REACT_APP_AUTH_HOST;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export function Signup() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const myContext = useContext(AppContext);
  const [loading, setLoading] = useState(false)
  // const [error, setError] = useState(false)
  // const [errorMsg, setErrorMsg] = useState("")

  const [formValues, setFormValues] = useState({
    firstName: {
      value: '',
      error: false,
      errorMessage: 'You must enter a first name'
    },
    lastName: {
      value: '',
      error: false,
      errorMessage: 'You must enter a last name'
    },
    password: {
      value: '',
      error: false,
      errorMessage: 'You must enter a password'
    },
    confirm_password: {
      value: '',
      error: false,
      errorMessage: 'You must enter a confirm password'
    },
    email: {
      value: '',
      error: false,
      errorMessage: 'You must enter an email'
    }
  })
  const [alert, setAlert] = React.useState({ 'open': false, 'message': '' });


  useEffect(() => {
    if (sessionStorage.getItem("signed_in") === "true") {
      navigate('/dashboard');
    }
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlert({ 'open': false, 'message': '' });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value)
    setFormValues({
      ...formValues,
      [name]: {
        ...formValues[name],
        value
      }
    })
  }

  const runValidations = () => {
    const formFields = Object.keys(formValues);
    let newFormValues = { ...formValues }
    console.log(formFields, newFormValues)
    var error_count = 0
    for (let index = 0; index < formFields.length; index++) {
      const currentField = formFields[index];
      console.log(" currentField", currentField)
      const currentValue = formValues[currentField].value;
      console.log("current value", currentValue)
      if (!currentValue || currentValue === '') {
        console.log("currentField empty", currentField)
        newFormValues = {
          ...newFormValues,
          [currentField]: {
            ...newFormValues[currentField],
            error: true,
          }
        }
        error_count++
      } else {
        newFormValues = {
          ...newFormValues,
          [currentField]: {
            ...newFormValues[currentField],
            error: false
          }
        }
      }
    }

    if (formValues.password.value !== formValues.confirm_password.value) {
      newFormValues = {
        ...newFormValues,
        'password': {
          ...newFormValues['password'],
          error: true,
          errorMessage: "Password and confirm password does not match"
        },
        'confirm_password': {
          ...newFormValues['confirm_password'],
          error: true,
          errorMessage: "Password and confirm password does not match"
        }
      }
      error_count++
    }

    if (formValues.password.value.length < 6) {
      newFormValues = {
        ...newFormValues,
        'password': {
          ...newFormValues['password'],
          error: true,
          errorMessage: "Password should be minimum of 6 character"
        }
      }
      error_count++
    }
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (!pattern.test(formValues.email.value)) {
      newFormValues = {
        ...newFormValues,
        'email': {
          ...newFormValues['email'],
          error: true,
          errorMessage: "Please enter valid email address."
        }
      }
      error_count++
    }
    console.log(newFormValues)
    setFormValues(newFormValues)
    console.log("new values has been set")
    return error_count
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const error_count = runValidations()
    if (error_count) return
    setLoading(true);

    const data = new FormData(event.currentTarget);
    console.log({
      firstName: data.get('firstName'),
      lastName: data.get('lastName'),
      email: data.get('email'),
      password: data.get('password'),
    });

    axios.post(`${baseauthURL}signup`, data)
      .then(function (response) {
        console.log(response);
        if (response?.status === 200 && response?.data?.message === "Successfully signed up.") {
          setLoading(false);
          navigate('/login', { state: { loginMsg: 'Check your email for verficiation' } })
        }
        else {
          setLoading(false)
          setAlert({ 'open': true, 'message': response.data?.message, severity: 'error' })
        }
      })
      .catch(function (error) {
        setLoading(false)
        setAlert({ 'open': true, 'message': error.response.data.message || "some error occured", severity: 'error' })
      });

  };

  return (
    <div>
      <Snackbar className=" mt-20" anchorOrigin={{ vertical: "top", horizontal: "center" }} open={alert.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alert.severity} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>

      {loading ? <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop> : ""}
      <Box className="flex flex-col mb-4 md:px-[30%] px-[5%] py-[6%] justify-center items-center place-content-center self-center selfce">
        <Card elevation={4} className="2xl:container  mt-2  py-8" style={{ borderRadius: 10, border: '1px', paddingLeft: "4%", paddingRight: "4%", alignContent: "center" }}>
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <BsFillQuestionCircleFill />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign up
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="given-name"
                    name="firstName"
                    required
                    fullWidth
                    variant="outlined"
                    id="firstName"
                    label="First Name"
                    size="small"
                    autoFocus
                    onChange={handleChange}
                    value={formValues.firstName.value}
                    error={formValues.firstName.error}
                    helperText={formValues.firstName.error && formValues.firstName.errorMessage}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="family-name"
                    size="small"
                    onChange={handleChange}
                    value={formValues.lastName.value}
                    error={formValues.lastName.error}
                    helperText={formValues.lastName.error && formValues.lastName.errorMessage}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    size="small"
                    onChange={handleChange}
                    value={formValues.email.value}
                    error={formValues.email.error}
                    helperText={formValues.email.error && formValues.email.errorMessage}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    size="small"
                    onChange={handleChange}
                    value={formValues?.password?.value}
                    error={formValues?.password?.error}
                    helperText={formValues?.password?.error && formValues?.password?.errorMessage}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    name="confirm_password"
                    label="Confirm Password"
                    type="password"
                    id="comfirm_password"
                    autoComplete="new-password"
                    size="small"
                    onChange={handleChange}
                    value={formValues?.confirm_password?.value}
                    error={formValues?.confirm_password?.error}
                    helperText={formValues?.confirm_password?.error && formValues?.confirm_password?.errorMessage}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{ background: "#4285f4", color: "white", textTransform: "none", marginTop: "16px" }}
                sx={{ mt: 8, mb: 2 }}
              >
                Sign Up
              </Button>
              <Grid container className="mt-2" justifyContent="flex-end">
                <Grid item>
                  <Link href="login" variant="body2">
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>

          {/* </h1> */}
        </Card>
      </Box>
    </div >
  );
}

export default Signup;