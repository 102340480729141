/**
 *
 * Login
 *
 */

import React, { useState, useEffect, useContext } from "react";
import AppContext from '../../components/AppContext/AppContext';
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Modal,
  TextField,
  Box,
  makeStyles,
} from "@material-ui/core";
import "./style.css";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import axios from 'axios';
import { Card } from "@material-ui/core";
import StarIcon from '@mui/icons-material/StarBorder';
import DoneIcon from '@mui/icons-material/Done';
import { useForm, ValidationError } from '@formspree/react';

const baseURL = process.env.REACT_APP_API_HOST;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
}));

export default function ContactForm(props) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);


  const [formValues, setFormValues] = useState({
    run_name: {
      value: '',
      error: false,
      errorMessage: 'You must enter a run name'
    },
    source_url: {
      value: '',
      error: false,
      errorMessage: 'You must enter a source url'
    }
  })

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    props.handleModalClose(false);

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Your submit logic here
    handleClose();
  };

  const [state, handleFormSubmit] = useForm("mpzbqdoq");

  return (
    <div>
      <Modal open={props.open} onClose={handleClose} className={classes.modal}>
        {state.succeeded ?
          (<div className={classes.paper} style={{ padding: '2vw' }}>
            <p>Thanks for connecting. We will get back to you shortly.</p>
            <br />
          </div>
          )
          : <div className={classes.paper}>
            <Typography variant="h4" align="center">
              Contact Us
            </Typography>
            <Box mt={2} mb={4}>
              <Typography variant="body1" align="center">
                Please fill out the form below and we will get back to you as
                soon as possible.
              </Typography>
            </Box>
            <form className={classes.form} onSubmit={handleFormSubmit}>
              <TextField
                required
                label="Name"
                variant="outlined"
                name="name"
              />
              <TextField
                required
                label="Email"
                variant="outlined"
                type="email"
                name="email"
              />
              <TextField
                required
                label="Message"
                variant="outlined"
                multiline
                rows={4}
                name="message"
              />
              <Button variant="contained" color="primary" type="submit" style={{ background: "#4285f4", color: "white", textTransform: "none" }}>
                Submit
              </Button>
            </form>
          </div>}
      </Modal>
    </div >
  );
}
