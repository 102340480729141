/**
 *
 * DataSource
 *
 */

import React, { useState, useEffect, useContext, useRef } from "react";
import AppContext from '../../components/AppContext/AppContext';
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import {
  Box,
  Button,
  TextField,
  FormControl,

  MenuItem,
  Card,
} from "@material-ui/core";
import "./CleansingPage.css";
import { HiArrowCircleLeft } from 'react-icons/hi';
import { NavLink, useNavigate } from "react-router-dom";
import HorizontalLabelPositionBelowStepper from "../../components/HorizontalStepperComponent";
import { DataGrid } from "@material-ui/data-grid";
import Dialog from '@mui/material/Dialog';
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from '@mui/material/CircularProgress';
import 'tw-elements';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';
import { Tooltip } from "@mui/material";
import { format } from "date-fns";
import { PRIMARY_COLOR } from "../../constants";
import { BsFillQuestionCircleFill } from 'react-icons/bs'
import IconButton from '@mui/material/IconButton';
import { handleApiCall } from "../../utils/request"

const baseURL = process.env.REACT_APP_API_HOST;

export function CleansingPage() {
  const myContext = useContext(AppContext);
  const navigate = useNavigate();

  console.log("my context....", myContext)
  const run_id = myContext.run_id;
  const data_source = myContext.data_source;

  const [rows, setRows] = useState([]);

  const updateDataCleansingState = async (redirect_to) => {
    console.log("inside updateDataCleansingLocalStorage", rows);
    myContext.setSelectedFeatures(rows)

    const payload = { "feature_details": rows }
    try {
      const response = await handleApiCall(`${baseURL}edaRun/${run_id}`, 'put', payload, navigate, myContext)

      console.log(response);
      console.log("saved intermediate data")
      // navigate(redirect_to);
    } catch (error) {
      console.log(error);
    }
  };
  const refOne = useRef(null)



  const hideOnEscape = (e) => {
    console.log("key", e.key)
    if (refOne.current && !refOne.current.contains(e.target)) {
      // setOpenDateRange(false)
    }
  }

  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      // setOpenDateRange(false)
    }
  }

  useEffect(() => {
    // document.addEventListener("keydown", hideOnEscape, true)
    // document.addEventListener("click", hideOnClickOutside, true)
    if (run_id === "") {
      // || data_source === ""
      console.log("values are empty")
      navigate('/dashboard');
    }
    console.log("SELECTED FEAÞURES...", myContext)
    const selected_features = myContext.selected_features;
    let inputRows = selected_features.filter(
      (row) => (row.is_input === 1)
    );
    setRows(inputRows);
    console.log("INPUT FEATURES....", inputRows, rows)

    return () => { };
  }, []);

  const handleFormDataChange = (newFormData) => {
    console.log("handleFormDataChange check", newFormData);
    if (newFormData) {
      setRows(newFormData);
      console.log(rows, "selectedRow check 2 formData");
    }
  };

  return (
    <div style={{ padding: '0% 15%' }}>
      <Helmet>
        <title>Dashboard</title>
        <meta name="description" content="Description of Dashboard" />
      </Helmet>

      <Box className="container-bg bg-contain md:p-0 p-5">
        <Box className="2xl:container m-auto py-5 md:pb-5 md:p-2  pt-5 md:pt-8">
          <Box className="flex flex-row items-center justify-center mb-8">
            <NavLink to="/data-source" onClick={(event) => { updateDataCleansingState("/data-source") }}>
              <span><HiArrowCircleLeft style={{ width: '66px', height: '66px', color: PRIMARY_COLOR }} /></span>
            </NavLink>
            <HorizontalLabelPositionBelowStepper activeStp={1} />
            <NavLink to="/data-tranformation" onClick={(event) => { updateDataCleansingState("/data-tranformation") }}>
              <span><HiArrowCircleLeft className="flip-image" style={{ width: '66px', height: '66px', color: PRIMARY_COLOR }} /></span>
            </NavLink>
          </Box>
          <Box className="container-bg bg-contain md:p-0 p-5">
            <Box className="2xl:container m-auto md:pb-5">
              <Card elevation={4} style={{ borderRadius: 10 }} className=" w-full">
                {rows.length ? <DataSourceTable rows={rows} onChangeRowData={handleFormDataChange} /> : ""}
              </Card>
            </Box>
          </Box>
          <Box className="flex flex-row items-center justify-center pr-20">
            <Box className=" mt-4 pr-20 flex flex-row items-center">
              <NavLink to="/data-source">
                <Button variant="contained" disableElevation style={{ backgroundColor: PRIMARY_COLOR, color: "white" }} onClick={(event) => { updateDataCleansingState("/data-source") }}>
                  <span className="w-full py-1 px-5">Back</span>
                </Button>
              </NavLink>
            </Box>
            <Box className=" mt-4 ">
              <NavLink to="/data-tranformation">
                <Button variant="contained" disableElevation style={{ background: PRIMARY_COLOR, color: "white" }} onClick={(event) => { updateDataCleansingState("/data-tranformation") }}>
                  <span className="w-full py-1 px-5"> Next </span>
                </Button>
              </NavLink>
            </Box>
          </Box>
        </Box>
      </Box>
    </div >
  );
}

export default CleansingPage;

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    // width: "8rem",
  }
}));

function DataSourceTable(props) {
  const navigate = useNavigate();
  const myContext = useContext(AppContext);
  const run_id = myContext.run_id
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [dateRange, setDateRange] = useState([null, null]);
  const [currentRow, setCurrentRow] = useState(null);
  const [activeChart, setActiveChart] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [openSettings, setOpenSettings] = React.useState(false);
  const [openDateRange, setOpenDateRange] = React.useState(false);
  const [selectionRange, setSelectionRange] = React.useState([{
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  }]);
  const [replacementMenuItems, setReplacementMenuItems] = React.useState(<MenuItem value="" >"None"</MenuItem>);
  const [outlierMenuItems, setOutlierMenuItems] = React.useState(<MenuItem value="" >"None"</MenuItem>);
  const [isDiabled, setIsDisabled] = React.useState({})
  const [errors, setErrors] = React.useState(
    {
      null_strategy:'', 
      null_repl_cstm_val:'',
      outlierMethod:'',
      outlier_detection_val:'',
      outlier_detection_val_min:'',
      outlier_detection_val_max:'',
      outlier_strategy:'',
      outlier_rep_cstm_val:''
    }
  )

  var replacementOptions = {
    "numerical": [
      {
        "method": "",
        "value": "None"
      },
      {
        "method": "remove_null",
        "value": "Remove null values"
      },
      {
        "method": "replace_with_mean",
        "value": "Replace null with Mean"
      },
      {
        "method": "replace_with_mode",
        "value": "Replace null with Mode"
      },
      {
        "method": "replace_with_median",
        "value": "Replace null with Median"
      },
      {
        "method": "replace_with_maximum",
        "value": "Replace null with Maximum"
      },
      {
        "method": "replace_with_minimum",
        "value": "Replace null with Minimum"
      },
      {
        "method": "custom_value",
        "value": "Custom Value"
      },
    ],
    "categorical": [
      {
        "method": "",
        "value": "None"
      },
      {
        "method": "remove_null",
        "value": "Remove null values"
      },
      {
        "method": "replace_with_most_frequent",
        "value": "Replace null with most frequent value"
      },
      {
        "method": "custom_value",
        "value": "Custom Value"
      },
    ],
    "date": [
      {
        "method": "",
        "value": "None"
      },
      {
        "method": "remove_null",
        "value": "Remove null values"
      }
    ]
  }
  // var replacementMenuItems = 

  var outlierOptions = {
    "numerical": [
      {
        "method": "",
        "value": "None"
      },
      {
        "method": "standard_deviation",
        "value": "Standard Deviation"
      },
      {
        "method": "acceptable_range",
        "value": "Acceptable Range"
      },
      {
        "method": "percentile_clipping",
        "value": "Percentile Clipping"
      },
      {
        "method": "z_score",
        "value": "Z-score"
      }
    ],
    "categorical": [
      {
        "method": "",
        "value": "None"
      },
      {
        "method": "out_of_vocab",
        "value": "Out of Vocab"
      }
    ],
    "date": [
      {
        "method": "",
        "value": "None"
      },
      {
        "method": "date_range",
        "value": "Date Range"
      }
    ]
  }
  // var outlierMenuItems = 

  const handleDateSelect = (ranges) => {
    console.log(ranges);
    // {
    //   selection: {
    //     startDate: [native Date Object],
    //     endDate: [native Date Object],
    //   }
    // }
  }

  // const [selectedRowData, setSelectedRowData] = React.useState([]);
  // const [checked, setChecked] = useState(false);

  // const toggleChecked = () => {
  //   setChecked((prev) => !prev);
  // };

  // const handleSelection = (newSelection) => {
  //   const selectedRowData = props.rows.filter(
  //     (row) => row.id === newSelection[0]
  //   );
  //   console.log(selectedRowData, "newSelection check");
  //   setSelectedRowData(selectedRowData);
  //   console.log(newSelection, "selectionModel check");
  // };

  // const refOne = useRef(null)

  // useEffect(() => {
  //   // document.addEventListener("keydown", hideOnEscape, true)
  //   // document.addEventListener("click", hideOnClickOutside, true)
  // }, []);

  // const hideOnEscape = (e) => {
  //   console.log(e)
  //   console.log(window.event)
  //   if (e.key === "Tab") {
  //     setOpenDateRange(false)
  //   }
  // }

  // const hideOnClickOutside = (e) => {
  //   // console.log(e.target)
  //   // console.log(refOne)
  //   if (refOne.current && !refOne.current.contains(e.target)) {
  //     setOpenDateRange(false)
  //   }
  // }

  useEffect(() => {
    generateGraph(page)
    // document.addEventListener("keydown", hideOnEscape, true)
    // document.addEventListener("click", hideOnClickOutside, true)
  }, []);

  const generateGraph = (newPage) => {
    // props.rows is empty at page load, handle it. 
    console.log("generating graph...", newPage, newPage * 5, ((newPage + 1) * 5 - 1), props.rows)
    setPage(newPage)
    const data = props.rows.slice(newPage * 5, ((newPage + 1) * 5))
    console.log("updated page", data)
    data.forEach(d => {
      handleApplyChanges(d)
    })
  }

  const onChangeNullPercentageStrategy = (e, id) => {
    console.log(id, e.target.value, "check toggleCheckedOutputFeature ");
    if(e.target.value===''){setIsDisabled(true)} 
    else {
      setIsDisabled(false);
      setErrors({...errors, null_strategy:''})

    }
    let selectedRow = props.rows.filter((row) => row.id === id)[0];
    console.log(selectedRow, "selectedRow before testing");
    let newFormData = props.rows.filter((row) => row.id !== id);
    selectedRow.null_strategy = e.target.value;
    if (e.target.value !== "custom_value")
      selectedRow.null_repl_cstm_val = undefined; //Revisit here and test.....changed null to undefined
    console.log(selectedRow, "selectedRow after testing");
    newFormData.push(selectedRow);
    newFormData.sort((a, b) => (a.id > b.id ? 1 : -1));
    props.onChangeRowData(newFormData);
    console.log(props.rows, "formData finally strategy");
  };

  const onChangeNullPercentageCustomValue = (e, id) => {
    console.log(id, e.target.value, "check onChangeNullPercentageCustomValue ");
    if(e.target.value===''){setIsDisabled(true)} 
    else{
      setIsDisabled(false)
      setErrors({...errors, null_repl_cstm_val:''})

    }
    let selectedRow = props.rows.filter((row) => row.id === id)[0];
    console.log(selectedRow, "selectedRow before testing");
    let newFormData = props.rows.filter((row) => row.id !== id);
    selectedRow.null_repl_cstm_val = e.target.value;
    console.log(selectedRow, "selectedRow after testing");
    newFormData.push(selectedRow);
    newFormData.sort((a, b) => (a.id > b.id ? 1 : -1));

    props.onChangeRowData(newFormData);
    console.log(props.rows, "formData finally strategy");
  };

  const onChangeOutlierMethod = (e, id) => {
    console.log(id, e.target.value, "check toggleCheckedOutputFeature ");
    if(e.target.value!=='')
    {
      setErrors({...errors, outlierMethod:''})
    }
    let selectedRow = props.rows.filter((row) => row.id === id)[0];
    console.log(selectedRow, "selectedRow before testing");
    let newFormData = props.rows.filter((row) => row.id !== id);
    selectedRow.outlierMethod = e.target.value;
    selectedRow.outlier_detection_val = ""
    selectedRow.outlier_detection_val_min = ""
    selectedRow.outlier_detection_val_max = ""
    if(e.target.value === '') selectedRow.outlierStrategy = ""
    console.log(selectedRow, "selectedRow after testing");
    newFormData.push(selectedRow);
    newFormData.sort((a, b) => (a.id > b.id ? 1 : -1));

    props.onChangeRowData(newFormData);
    console.log(props.rows, "onChangeOutlierMethod formData finally strategy");
  };

  const onChangeOutlierMethodValue = (e, id, param) => {
    console.log(id, e, "check onChangeOutlierCustomeValue ");
    console.log(id, e, "check toggleCheckedOutputFeature ");
    setDateRange(e);
    if(e?.target?.value!=='')
    {
      setErrors({...errors, [param]:''})
    }
    let selectedRow = props.rows.filter((row) => row.id === id)[0];
    console.log(selectedRow, "selectedRow before testing");
    let newFormData = props.rows.filter((row) => row.id !== id);
    if (param === "outlier_detection_val_min")
      selectedRow['outlier_detection_val_min'] = e.target.value
    else if (param === "outlier_detection_val_max")
      selectedRow['outlier_detection_val_max'] = e.target.value
    else if (param === "outlier_detection_date_range") {
      selectedRow["outlier_detection_val_min"] = format(e.selection.startDate, "dd/MM/yyyy")
      selectedRow["outlier_detection_val_max"] = format(e.selection.endDate, "dd/MM/yyyy")
    }
    else
      selectedRow.outlier_detection_val = e.target.value;
    console.log(selectedRow, "selectedRow after testing");
    newFormData.push(selectedRow);
    newFormData.sort((a, b) => (a.id > b.id ? 1 : -1));

    props.onChangeRowData(newFormData);
    console.log(props.rows, "formData finally strategy");
  };

  const onChangeOutlierStrategy = (e, id) => {
    console.log(id, e, "check onChangeOutlierStrategy  ");
    if(e.target.value!=='')
    {
      setErrors({...errors, outlier_strategy:''})
    }
    let selectedRow = props.rows.filter((row) => row.id == id)[0];
    console.log(selectedRow, "selectedRow before testing");
    let newFormData = props.rows.filter((row) => row.id != id);
    selectedRow.outlierStrategy = e.target.value
    if (e.target.value !== "custom_value")
      selectedRow.outlier_rep_cstm_val = "";
    console.log(selectedRow, "selectedRow after testing");
    newFormData.push(selectedRow);
    newFormData.sort((a, b) => (a.id > b.id ? 1 : -1));

    props.onChangeRowData(newFormData);
    console.log(
      props.rows,
      "onChangeOutlierStrategy formData finally strategy"
    );
  };

  const onChangeOutlierStrategyValue = (e, id) => {
    console.log(id, e, "check toggleCheckedOutputFeature ");
    if(e.target.value!=='')
    {
      setErrors({...errors, outlier_rep_cstm_val:''})
    }
    let selectedRow = props.rows.filter((row) => row.id === id)[0];
    console.log(selectedRow, "selectedRow before testing");
    let newFormData = props.rows.filter((row) => row.id != id);
    selectedRow.outlier_rep_cstm_val = e.target.value;
    console.log(selectedRow, "selectedRow after testing");
    newFormData.push(selectedRow);
    newFormData.sort((a, b) => (a.id > b.id ? 1 : -1));

    props.onChangeRowData(newFormData);
    console.log(props.rows, "formData finally strategy");
  };

  const handleCurrentRow = (currentRow) => {
    setCurrentRow(currentRow)
    console.log(" handleApplyChanges currentRowData...", currentRow)

    if (["date", "time", "datetime", "timestamp"].indexOf(currentRow?.fnl_data_type) > -1) {
      setReplacementMenuItems(replacementOptions["date"].map(ro => <MenuItem value={ro.method} >{ro.value}</MenuItem>))
      setOutlierMenuItems(outlierOptions["date"].map(ro => <MenuItem value={ro.method} >{ro.value}</MenuItem>))
    }
    else {
      setReplacementMenuItems(replacementOptions[currentRow?.fnl_data_type].map(ro => <MenuItem value={ro.method} >{ro.value}</MenuItem>))
      setOutlierMenuItems(outlierOptions[currentRow?.fnl_data_type].map(ro => <MenuItem value={ro.method} >{ro.value}</MenuItem>))
    }
  }

  const handleApplyChanges = async (currentRowData, isApplied = false) => {
    console.log("in handleApplyChanges")
    currentRowData["chart_loading"] = true
    let errors = {}
    // if(!currentRowData.null_strategy || currentRowData.null_strategy ===''){
    //   console.log('error')
    //   // setErrors({...errors, null_strategy:'Please select a null strategy.'})
    //   errors = {...errors, null_strategy:'Please select a null strategy.'}
    //   // return;
    // }
    if(currentRowData.null_strategy ==='custom_value' && (!currentRowData.null_repl_cstm_val || currentRowData.null_repl_cstm_val === '')){
      // setErrors({...errors, null_repl_cstm_val:'Please enter the custom value.'})
      errors = {...errors, null_repl_cstm_val:'Please enter the custom value.'}
      // return;
    }


    const requestObj = {
      "col": currentRowData.feature_name,
      "run_id": run_id,
      "null_strategy": currentRowData.null_strategy,
      "null_repl_cstm_val": currentRowData.null_repl_cstm_val,
      "outlier_strategy": currentRowData.outlierStrategy,
      "outlier_rep_cstm_val": currentRowData.outlier_rep_cstm_val
    }
    if (currentRowData.outlierMethod && currentRowData.outlierMethod !== "") {
      // console.log("setting outlier method...", currentRowData.outlierMethod)
      requestObj["outlier_method"] = currentRowData.outlierMethod
      if (requestObj["outlier_method"] === "date_range")
        requestObj["outlier_strategy"] = "remove"
    } 
    // else {
    //   errors = ({...errors, outlierMethod:'Please select an outlier method.'})
    //   // setErrors({...errors, outlierMethod:'Please select an outlier method.'})
    //   // return;
    // }
    if (currentRowData.outlier_detection_val && currentRowData.outlier_detection_val !== "") {
      // console.log("getting outlier_detection_val")
      requestObj["outlier_detection_val"] = currentRowData.outlier_detection_val
    } 
    else if (currentRowData.outlier_detection_val_min && currentRowData.outlier_detection_val_max && currentRowData.outlier_detection_val_min !== "" && currentRowData.outlier_detection_val_max !== "") {
      console.log("getting outlier_detection_val min nd max")
      requestObj["outlier_detection_val"] = currentRowData.outlier_detection_val_min + " " + currentRowData.outlier_detection_val_max
    } else {
      console.log("getting outlier_detection_val min nd max error")
      if((currentRowData.outlierMethod==='standard_deviation' || currentRowData.outlierMethod==='z_score' || currentRowData.outlierMethod==='out_of_vocab')&&(!currentRowData.outlier_detection_val || currentRowData.outlier_detection_val === "")){
        errors = ({...errors, outlier_detection_val:'Please enter an outlier value.'}) 
      } else { 
        if(currentRowData.outlierMethod && currentRowData.outlier_detection_val_min === "" && currentRowData.outlier_detection_val_max === ""){
          errors = ({...errors, outlier_detection_val_min: 'Please enter minimum outlier value.', outlier_detection_val_max:'Please enter maximum outlier value.'})
        }
        if(currentRowData.outlierMethod && currentRowData.outlier_detection_val_min === ""){
          errors = ({...errors, outlier_detection_val_min:'Please enter minimum outlier value.'})
        }
        if(currentRowData.outlierMethod && currentRowData.outlier_detection_val_max === ""){
          errors = ({...errors, outlier_detection_val_max:'Please enter maximum outlier value.'})
        }
    }
    }
    if((currentRowData.outlierMethod && (currentRowData.outlierMethod !=='' && currentRowData.outlierMethod !=='date_range' && currentRowData.outlierMethod !=='out_of_vocab')) && (!currentRowData.outlierStrategy || currentRowData.outlierStrategy=='')){
      console.log('error')
      errors = ({...errors, outlier_strategy:'Please select a outlier strategy.'})
    }
    if(currentRowData.outlierStrategy ==='custom_value' && (!currentRowData.outlier_rep_cstm_val || currentRowData.outlier_rep_cstm_val === '')){
      console.log('error')
      errors = ({...errors, outlier_rep_cstm_val:'Please enter the custom value.'})

    }
    console.log(requestObj)
    if(isApplied && Object.keys(errors).length>0){
      console.log('errors',errors)
      setErrors(errors)
      return;
    }
    setOpenSettings(false)
    try {
      const response = await handleApiCall(`${baseURL}getCleansedCharts`, 'post', requestObj, navigate, myContext)
      currentRowData["chart_loading"] = false
      console.log(response);
      let selectedRow = props.rows.filter(row => row.id === currentRowData.id)[0]
      let newFormData = props.rows.filter(row => row.id !== currentRowData.id)
      selectedRow.cleansed_chart_signed_url = response?.data?.signed_url
      newFormData.push(selectedRow)

      newFormData.sort((a, b) => (a.id > b.id) ? 1 : -1)
      props.onChangeRowData(newFormData)
      console.log("props updated")
    } catch (error) {
      console.log(error);
      currentRowData["chart_loading"] = false
    }
  }

  const handleClose = () => {
    setOpen(false);
    setOpenSettings(false)
    setOpenDateRange(false);
    setErrors(    {
      null_strategy:'', 
      null_repl_cstm_val:'',
      outlierMethod:'',
      outlier_detection_val:'',
      outlier_detection_val_min:'',
      outlier_detection_val_max:'',
      outlier_strategy:'',
      outlier_rep_cstm_val:''
    })
  };

  const columns = [
    { field: "id", headerName: "ID", width: 100, hide: true },
    { field: "feature_name", headerName: "Feature", flex: 1, minWidth: '100' },
    {
      field: "nullTreatment",
      flex: 1.3,
      minWidth: 220,
      height: 400,
      headerName: "Null Treatment",
      // description: 'Select the null replacement strategy to remove the null data.',
      renderCell: (params) => {
        let currentRowData = props.rows.filter((row) => row.id === params.row.id);
        console.log(currentRowData, "currentRowData check");
        console.log(params, "params...");

        return (
          <div className="flex flex-col leading-6" >
            <Box className="flex flex-col  pr-12">
              <p className="text-gray-400">Current Null %</p>
              {currentRowData[0]?.null_perct || "-"}
            </Box>
            <Box className="flex flex-row mt-4">
              <Box className="flex flex-col  pr-4">
                <p className="text-gray-400">Null Replacement Strategy</p>
                <p className="">{currentRowData[0]?.null_strategy || "None"}</p>
              </Box>
              {currentRowData && currentRowData[0].null_strategy === "custom_value" ? (
                <Box className="flex flex-col">
                  <p className="text-gray-400">Value</p>
                  {currentRowData[0]?.null_repl_cstm_val || "-"}
                </Box>
              )
                : (
                  ""
                )
              }
            </Box>
          </div >
        );
      },
    },
    {
      field: "outlierTreatment",
      flex: 1.6,
      minWidth: 260,
      height: 600,
      headerName: "Outlier Treatment",
      // description: '',
      // headerClassName: 'lastcolumnSeparator',
      renderCell: (params) => {
        let currentRow = props.rows.filter((row) => row.id === params.row.id)[0];
        var menuItems = <MenuItem value="" >"None"</MenuItem>
        if (["date", "time", "datetime", "timestamp"].indexOf(currentRow?.fnl_data_type) > -1) {
          menuItems = outlierOptions["date"].map(ro => <MenuItem value={ro.method} >{ro.value}</MenuItem>)
        }
        else {
          console.log("final data type", currentRow.fnl_data_type)
          menuItems = outlierOptions[currentRow?.fnl_data_type].map(ro => <MenuItem value={ro.method} >{ro.value}</MenuItem>)
        }
        return (
          <div className="flex flex-col leading-6">
            {currentRow?.fnl_data_type === "numerical" ?
              (
                <Box className="flex flex-col  pr-12">
                  <Box className="flex flex-row pr-8" style={{}}>
                    <Box className="flex flex-col pr-8 w-52">
                      <p className="text-gray-400">Outlier Identification Method</p>
                      {currentRow?.outlierMethod || "None"}
                    </Box>
                    {currentRow && ((currentRow.outlierMethod === "standard_deviation") || (currentRow.outlierMethod === "z_score")) ? (
                      <Box className="flex flex-col pr-4">
                        <p className="text-gray-400">Value</p>
                        {currentRow?.outlier_detection_val || "-"}
                      </Box>
                    )
                      : (
                        ""
                      )
                    }
                    {currentRow && ((currentRow.outlierMethod === "acceptable_range") || (currentRow.outlierMethod === "percentile_clipping")) ? (
                      <Box className="flex flex-row">
                        <Box className="flex flex-col pr-6">
                          <p className="text-gray-400 ">Min</p>
                          {currentRow?.outlier_detection_val_min || "-"}
                        </Box>
                        <Box className="flex flex-col">
                          <p className="text-gray-400">Max</p>
                          {currentRow?.outlier_detection_val_max || "-"}
                        </Box>
                      </Box>
                    )
                      : (
                        ""
                      )
                    }
                  </Box>
                  <Box className="flex flex-row mt-4 " style={{}}>
                    <Box className="flex flex-col w-52">
                      <p className="text-gray-400">Strategy</p>
                      {currentRow?.outlierStrategy || "-"}
                    </Box>
                    {currentRow && currentRow.outlierStrategy === "custom_value" ? (
                      <Box className="flex flex-col">
                        <p className="text-gray-400">Value</p>
                        {currentRow?.outlier_rep_cstm_val || "-"}
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>

                </Box>
              ) :
              (
                <Box className="flex flex-col  pr-12">
                  <Box className="flex flex-col pr-12 w-4/5">
                    <p className="text-gray-400">Outlier Identification Method</p>
                    {currentRow?.outlierMethod || "None"}
                  </Box>
                  {currentRow && ((currentRow.outlierMethod === "date_range")) ? (
                    <Box className="flex flex-col mt-4">
                      <p className="text-gray-400">Date Range</p>
                      {`${format(selectionRange[0].startDate, "MM/dd/yyyy")} to ${format(selectionRange[0].endDate, "MM/dd/yyyy")}` || "-"}
                    </Box>
                  )
                    : (
                      ""
                    )
                  }
                  {currentRow && (currentRow.outlierMethod === "out_of_vocab") ? (
                    <Box className="flex flex-col mt-4">
                      <p className="text-gray-400">Values</p>
                      {currentRow?.outlier_detection_val || "-"}
                    </Box>
                  )
                    : (
                      ""
                    )
                  }
                </Box>
              )
            }

          </div >

        );
      },
    },
    {
      field: " ",
      flex: 0.4,
      width: 100,
      headerName: "",
      headerClassName: 'lastcolumnSeparator',
      // hide: true,
      options: { customHeadRender: () => null },
      renderCell: (params) => {
        let currentRowData = props.rows.filter(row => row.id === params.row.id)
        return (
          <div>
            {
              (params.row.fnl_data_type === "time") ? "" :
                (<div className="flex flex-col">
                  <Button
                    variant="contained"
                    onClick={() => { handleCurrentRow(currentRowData[0]); setOpenSettings(true) }}
                    // onClick={() => { setOpenSettings(true) }}
                    style={{ backgroundColor: PRIMARY_COLOR, color: "white" }}
                  >
                    <div> Edit  </div>
                  </Button>
                  {/* <Button
                    className="mt-18"
                    variant="contained"
                    onClick={() => { handleApplyChanges(currentRowData[0]); }}
                    style={{ backgroundColor: PRIMARY_COLOR, color: "white", marginTop: "8px" }}
                  >
                    <div> Apply  </div>
                  </Button> */}
                </div>)
            }
          </div>
        );
      },
    },
    {
      field: "signed_url",
      flex: 1.5,
      headerClassName: 'lastcolumnSeparator',
      renderCell: (params) => {
        let currentRowData = props.rows.filter(row => row.id === params.row.id)
        if (params.row.fnl_data_type === "time") {
          return (
            <></>
          )
        }
        else
          if (currentRowData[0]["chart_loading"]) {
            return (
              <>
                <Box className="flex flex-col w-full p-5" alignItems="center">
                  <CircularProgress size="2rem" />
                </Box>
              </>)
          }
          else if (params.row.cleansed_chart_signed_url) {
            return (
              <>
                <Box className="flex flex-col w-full p-5 hover:cursor-zoom-in" onClick={() => {
                  setActiveChart(currentRowData[0].cleansed_chart_signed_url);
                  setOpen(true)
                }}>
                  <img
                    src={currentRowData[0].cleansed_chart_signed_url}
                    width="80%"
                    height="80%"
                    className="  align-top"
                    alt="Apply null treatment to see distribution chart"
                  />
                </Box>
              </>
            );
          } else return (<>Apply null treatment on data and see distribution chart</>)
      }
    },
  ];
  return (
    <div style={{ height: "60vh", width: "100%" }}>
      <Dialog open={open} onClose={handleClose} maxWidth={"md"}>
        <img
          className="image"
          src={activeChart}
          alt="charts"
        />
      </Dialog>
      <Dialog open={openSettings} onClose={handleClose} maxWidth={"md"}>
        <div className="flex flex-col p-8" >
          <div className="flex flex-row ">
            <div className="flex flex-col px-12">
              <div className="flex flex-row mb-8 items-center">
                <h1 className="text-blue-500  text-xl font-bold">Null Treatment</h1>
                <Tooltip title="Select the null replacement strategy to remove the null data." placement="bottom-start">
                  <IconButton>
                    <BsFillQuestionCircleFill />
                  </IconButton>
                </Tooltip>
              </div>
              <div className="flex flex-col" style={{}}>
                <FormControl required>
                  {/* <InputLabel id="strategy" style={{ fontSize: "12px" }}>Null Replacement Strategy</InputLabel> */}
                  <TextField
                    select
                    labelId="Strategy"
                    variant="outlined"
                    label="Null Replacement Strategy" // add this 
                    value={currentRow?.null_strategy}
                    id="strategy"
                    // className={classes.textField}
                    onChange={(e) =>
                      onChangeNullPercentageStrategy(e, currentRow.id)
                    }
                    inputlabelprops={{ style: { fontSize: 8 } }} // font size of input label
                    style={{ width: "16vw" }}
                    size="small"
                  >
                    {replacementMenuItems}
                  </TextField>
                </FormControl>
                {currentRow && currentRow.null_strategy === "custom_value" ? (
                  <TextField
                    style={{ fontSize: "12px", marginTop: "8px" }}
                    id="value"
                    label="Value"
                    variant="outlined"
                    className="mt-8"
                    value={currentRow?.null_repl_cstm_val}
                    type="text"
                    required
                    onChange={(e) =>
                      onChangeNullPercentageCustomValue(e, currentRow.id)
                    }
                    inputProps={{ style: { fontSize: 12 } }} // font size of input text
                    inputlabelprops={{ style: { fontSize: 12 } }} // font size of input label
                    size="small"
                    disabled={
                      props.rows.filter((row) => row.id === currentRow.id)[0].null_strategy !== "custom_value"
                    }
                    error={errors.null_repl_cstm_val && errors.null_repl_cstm_val!==''}
                    helperText={errors.null_repl_cstm_val!=='' && errors.null_repl_cstm_val}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="flex flex-col px-12">
              <Box className="flex flex-col" style={{}}>
                <div className="flex flex-row mb-8 items-center">
                  <h1 className="text-blue-500 text-xl font-bold">Outlier Treatment</h1>
                  <Tooltip title="Select the outlier identification method and strategy to remove the outlier from data." placement="bottom-start">
                    <IconButton>
                      <BsFillQuestionCircleFill />
                    </IconButton>
                  </Tooltip>
                </div>
                <FormControl required className="">
                  <TextField
                    select
                    labelId="method"
                    id="strategy"
                    variant="outlined"
                    label="Outlier Identification Method"
                    className={classes.textField}
                    value={currentRow?.outlierMethod}
                    onChange={(e) => onChangeOutlierMethod(e, currentRow.id)}
                    style={{ width: "16vw" }}
                    size="small"
                  >
                    {outlierMenuItems}
                  </TextField>
                </FormControl>
                <Box className="flex flex-row" style={{}}>
                  {currentRow &&
                    ((currentRow.outlierMethod === "standard_deviation") || (currentRow.outlierMethod === "z_score") || (currentRow.outlierMethod === "out_of_vocab")) ? (
                    <Tooltip title={currentRow.outlierMethod === "out_of_vocab" ? "Fill List of categories seperated by comma. i.e. 'Red, Yellow, Black'" : ""} arrow >
                      <TextField
                        id="value"
                        label="Value"
                        type="text"
                        variant="outlined"
                        required
                        value={currentRow?.outlier_detection_val}
                        className={classes.textField}
                        onChange={(e) =>
                          onChangeOutlierMethodValue(
                            e,
                            currentRow.id,
                            "outlier_detection_val"
                          )
                        }
                        inputProps={{ style: { fontSize: 12 } }} // font size of input text
                        inputlabelprops={{ style: { fontSize: 12 } }} // font size of input label
                        style={{ width: "16vw" }}
                        size="small"
                        error={errors.outlier_detection_val && errors.outlier_detection_val!==''}
                        helperText={errors.outlier_detection_val!=='' && errors.outlier_detection_val}
                      />
                    </Tooltip>
                  ) : (
                    ""
                  )}

                  {currentRow && ((currentRow.outlierMethod === "acceptable_range") || (currentRow.outlierMethod === "percentile_clipping")) ? (
                    <>
                      <TextField
                        id="value"
                        label="Min"
                        type="text"
                        variant="outlined"
                        required
                        value={currentRow?.outlier_detection_val_min}
                        className={classes.textField}
                        onChange={(e) =>
                          onChangeOutlierMethodValue(
                            e,
                            currentRow.id,
                            "outlier_detection_val_min"
                          )
                        }
                        inputProps={{ style: { fontSize: 12 } }} // font size of input text
                        inputlabelprops={{ style: { fontSize: 12 } }} // font size of input label
                        size="small"
                        style={{ width: "7.5vw" }}
                        error={errors.outlier_detection_val_min!==''}
                        helperText={errors.outlier_detection_val_min!=='' && errors.outlier_detection_val_min}
                      />
                      <TextField
                        id="value"
                        label="Max"
                        type="text"
                        variant="outlined"
                        required
                        value={currentRow?.outlier_detection_val_max}
                        className={classes.textField}
                        onChange={(e) =>
                          onChangeOutlierMethodValue(
                            e,
                            currentRow.id,
                            "outlier_detection_val_max"
                          )
                        }
                        inputProps={{ style: { fontSize: 12 } }} // font size of input text
                        inputlabelprops={{ style: { fontSize: 12 } }} // font size of input label
                        style={{ width: "7.5vw" }}
                        size="small"
                        error={errors.outlier_detection_val_max!==''}
                        helperText={errors.outlier_detection_val_max!=='' && errors.outlier_detection_val_max}
                      />
                    </>
                  ) : (
                    ""
                  )}

                  {currentRow && ((currentRow.outlierMethod === "date_range")) ? (
                    <div style={{ height: "6vh", marginTop: "2vh" }}>
                      <TextField
                        variant="outlined"
                        value={`${format(selectionRange[0].startDate, "MM/dd/yyyy")} to ${format(selectionRange[0].endDate, "MM/dd/yyyy")}`}
                        onClick={() => {
                          setOpenDateRange(open => !open)
                          console.log("clicking")
                        }}
                        size="small"
                        style={{ width: "12vw", fontSize: "12px" }}>
                      </TextField>
                      <br /><br />
                      <Dialog open={openDateRange} onClose={handleClose} maxWidth={"md"}>
                        <DateRangePicker
                          ranges={selectionRange}
                          onChange={(item) => {
                            onChangeOutlierMethodValue(
                              item,
                              currentRow.id,
                              "outlier_detection_date_range"
                            )
                            setSelectionRange([item.selection]);
                            console.log(item, format(item.selection.startDate, "dd/MM/yyyy"))
                          }}
                          editableDateInputs={true}
                          moveRangeOnFirstSelection={false}
                          month={1}
                          direction="horizontal"
                          className="calenderElement"
                        />
                      </Dialog>
                    </div>
                  ) : (
                    ""
                  )}
                </Box>
              </Box >
              {currentRow?.fnl_data_type === "numerical" ? (
                <Box className="flex flex-col mt-8" style={{}}>
                  <FormControl className="w-full" required>
                    {/* <InputLabel id="strategy" style={{ fontSize: "12px" }}> Strategy </InputLabel> */}
                    <TextField
                      select
                      labelId="Strategy"
                      label="Strategy"
                      id="strategy"
                      variant="outlined"
                      value={currentRow?.outlierStrategy}
                      className={classes.textField}
                      onChange={(e) => onChangeOutlierStrategy(e, currentRow.id)}
                      style={{ width: "16vw" }}
                      size="small"
                      error={currentRow.outlierMethod && errors.outlier_strategy && errors.outlier_strategy!==''}
                      helperText={errors.outlier_strategy!=='' && currentRow.outlierMethod && errors.outlier_strategy}
                    // value={formData.gender}
                    // onChange={e => setformData({ ...formData, gender: e.target.value })}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={"remove"}> Remove </MenuItem>
                      <MenuItem value={"replace_with_threshold"}>
                        {" "}
                        Replace with Threshold{" "}
                      </MenuItem>

                      <MenuItem value="custom_value">Custom Value</MenuItem>
                    </TextField>
                  </FormControl>

                  {currentRow && currentRow.outlierStrategy === "custom_value" ? (
                    <TextField
                      id="value"
                      label="Value"
                      variant="outlined"
                      value={currentRow.outlier_rep_cstm_val}
                      type="text"
                      required
                      className={classes.textField}
                      onChange={(e) =>
                        onChangeOutlierStrategyValue(
                          e,
                          currentRow.id,
                        )
                      }
                      inputlabelprops={{ style: { fontSize: 12 } }} // font size of input label
                      style={{ width: "16vw" }}
                      size="small"
                      error={errors.outlier_rep_cstm_val!==''}
                      helperText={errors.outlier_rep_cstm_val!=='' && errors.outlier_rep_cstm_val}
                    />
                  ) : (
                    ""
                  )}
                </Box>) : ""
              }
            </div >
          </div>
          <div className="text-center mt-8"><Button
            variant="contained"
            onClick={() => { handleApplyChanges(currentRow, true) }}
            style={{ backgroundColor: PRIMARY_COLOR, color: "white" }}
            // disabled={isDiabled}
          >
            <div> Apply  </div>
          </Button></div>
        </div>
      </Dialog>
      <DataGrid
        GridLinesVisibility="None"
        rowHeight={160}
        rows={props.rows}
        columns={columns}
        checkboxSelection={false}
        pageSize={5}
        rowsPerPageOptions={[5]}
        onPageChange={(newPage) => generateGraph(newPage)}
        // onSelectionModelChange={(newSelection) => {
        //   handleSelection(newSelection);
        // }}
        disableColumnSelector={true}
      />
    </div >
  );
}
