/**
 *
 * Login
 *
 */

import React, { useState, useEffect, useContext } from "react";
import AppContext from '../../components/AppContext/AppContext';
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { handleApiCall } from "../../utils/request"
import {
  Box,
  Button,
  TextField,
} from "@material-ui/core";
import "./style.css";
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { BsFillQuestionCircleFill } from 'react-icons/bs'

import Backdrop from '@mui/material/Backdrop';
import axios from 'axios';
import { PRIMARY_COLOR } from "../../constants";
import { Card } from "@material-ui/core";
import { setToken, getAccessToken, getRefreshToken } from "../../utils/token";
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const baseURL = process.env.REACT_APP_API_HOST;
const baseauthURL = process.env.REACT_APP_AUTH_HOST;

export function Profile() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const myContext = useContext(AppContext);
  const [loading, setLoading] = useState(false)
  const [formValues, setFormValues] = useState({
    run_name: {
      value: '',
      error: false,
      errorMessage: 'You must enter a run name'
    },
    source_url: {
      value: '',
      error: false,
      errorMessage: 'You must enter a source url'
    }
  })
  const [alert, setAlert] = React.useState({ 'open': false, 'message': '' });
  const [user_det, setuser_det] = React.useState({});

  useEffect(() => {
    const token = getAccessToken()
    if (!token) {
      navigate("/login")
    }
  }, [])


  const get_user_details = async () => {
    setLoading(true);
    const user_id = sessionStorage.getItem('user_id');
    const response = await handleApiCall(`${baseURL}user-profile/${user_id}`, 'get', {}, navigate, myContext)

    if (response?.status == 200) {
      // console.log(response,"profile handle ApiCall")
      setuser_det(response?.data);
      setLoading(false);
      // setAlert({ 'open': true, 'message': response?.data?.message, severity: 'success' })
    }
    else {
      setLoading(false)
      setAlert({ 'open': true, 'message': response?.data?.message, severity: 'error' })
    }
  }

  useEffect(() => {
    get_user_details();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlert({ 'open': false, 'message': '' });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value)
    setFormValues({
      ...formValues,
      [name]: {
        ...formValues[name],
        value
      }
    })
  }

  const runValidations = () => {
    const formFields = Object.keys(formValues);
    let newFormValues = { ...formValues }
    console.log(formFields, newFormValues)
    var error_count = 0
    for (let index = 0; index < formFields.length; index++) {
      const currentField = formFields[index];
      const currentValue = formValues[currentField].value;

      if (currentValue === '') {
        newFormValues = {
          ...newFormValues,
          [currentField]: {
            ...newFormValues[currentField],
            error: true
          }
        }
        error_count++
      } else {
        newFormValues = {
          ...newFormValues,
          [currentField]: {
            ...newFormValues[currentField],
            error: false
          }
        }
      }
    }
    console.log(newFormValues)
    setFormValues(newFormValues)
    console.log("new values has been set")
    return error_count
  }


  return (
    <div>
      <Snackbar className=" mt-20" anchorOrigin={{ vertical: "top", horizontal: "center" }} open={alert.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alert.severity} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
      {loading ? <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop> : ""}
      <Box className="flex flex-col mb-4 md:px-[15%] px-[5%] py-[6%] justify-center items-center place-content-center self-center selfce">
        <Card elevation={4} className="2xl:container  mt-2  py-4" style={{ borderRadius: 10, border: '1px', paddingLeft: "4%", paddingRight: "4%", alignContent: "center", width: "100%" }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',

            }}
          >
            <Grid item xs={12} className="bg-white font-bold text-2xl py-3" style={{ color: PRIMARY_COLOR }}> Profile Details </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <div>
                  <div style={{ marginLeft: "5px" }}>Name</div>
                  <p style={{ marginLeft: "5px", fontWeight: "bold" }}>
                    {user_det && user_det['name']}
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <div style={{ marginLeft: "5px" }}>Email</div>
                  <p style={{ marginLeft: "5px", fontWeight: "bold" }}>
                    {user_det && user_det['email']}
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <div style={{ marginLeft: "5px" }}>Active Plan</div>
                  <p style={{ marginLeft: "5px", fontWeight: "bold" }}>
                    {user_det && user_det['subscription']} &nbsp;&nbsp;<span className="text-base text-blue-400 underline cursor-pointer"><a href="subscriptions">Change Subscription</a></span>
                  </p>
                </div>
              </Grid>
            </Grid>
          </Box>

          {/* </h1> */}
        </Card>
      </Box>
    </div >
  );
}

export default Profile;