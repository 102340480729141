import axios from 'axios';
import { handleApiCall } from "../utils/request"

const { default: BaseAPI } = require("./base.api");

const host = process.env.REACT_APP_API_HOST;
class _EdaAPI extends BaseAPI {

    async getEDARuns() {
        console.log("calling api")
        return await axios.get(host + '/edaRuns')
    }

    async downloadPDF(runId, navigate, myContext) {
        const response = await handleApiCall(`${host}edaRuns/${runId}/pdf`, 'get', { responseType: 'blob' }, navigate, myContext)
        return response
    }

    async downloadProcessedData(runId, navigate, myContext) {
        const response = await handleApiCall(`${host}edaRuns/${runId}/data?file_format=csv`, 'get', { responseType: 'blob' }, navigate, myContext)
        return response
    }

    async createEDARunWithoutDataProcess(runName, bigQueryURL) {
        return await this.post('http://localhost:5000/createEDARunWithoutDataProcess/:runId/pdf', {

        })
    }

    async createEDARunWithDataProcess(transformationData, cleansingData, sourceData) {
        return await this.post('http://localhost:5000/createEDARunWithDataProcess', {
            transformationData, cleansingData, sourceData

        })
    }


    async createEDARun(runId) {
        return await this.post('http://localhost:5000/edaRuns/:runId/pdf', {

        })
    }

    async getTableDetails(data_source, run_id, navigate, myContext) {
        const response = await handleApiCall(`${host}getTableDetails?run_id=${run_id}`, 'get', {}, navigate, myContext)
        return response
    }

    async getSampleRecords(data_source, run_id, navigate, myContext) {
        const response = await handleApiCall(`${host}getSampleRecords?run_id=${run_id}`, 'get', {}, navigate, myContext)
        return response
    }


}

export const EdaAPI = new _EdaAPI();

