/**
 *
 * Dashboard
 *
 */

import React, { useState, useEffect, useContext, useRef } from "react";
import AppContext from '../../components/AppContext/AppContext';
import { NavLink, useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  Box,
  Button,
  TextField,
} from "@material-ui/core";
import "./dashboard.css";
import Grid from '@mui/material/Grid';
import { DataGrid } from "@material-ui/data-grid";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { EdaAPI } from "../../apis/eda.api";
import moment from 'moment'
import { PRIMARY_COLOR } from "../../constants";
import { Card } from "@material-ui/core";
import { BsFillQuestionCircleFill } from 'react-icons/bs'
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { handleApiCall } from "../../utils/request"


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const baseURL = process.env.REACT_APP_API_HOST;

export function Dashboard() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [statusMessage, setStatusMessage] = useState(state)
  const [file, setFile] = useState(null);

  const myContext = useContext(AppContext);
  const [rowData, setRowData] = useState([]);
  const [rows, setrows] = useState([]);
  const [loading, setLoading] = useState(false)
  const [formValues, setFormValues] = useState({
    run_name: {
      value: '',
      error: false,
      errorMessage: 'You must enter a run name'
    },
    source_url: {
      value: '',
      error: false,
      errorMessage: 'You must enter a source url'
    },
    file: {
      value: '',
      error: false,
      errorMessage: 'You must select a file'
    }
  })
  const [alert, setAlert] = React.useState({ 'open': false, 'message': '' });
  useEffect(() => {
    myContext.clearContext()
    if (searchParams.get("subscription_payment") === "success") {
      setAlert({ "open": true, "message": "Succesfully subscribed", severity: "success" })
    }
    if (!myContext?.signed_in) {
      navigate("/login")
    }
  }, [])


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlert({ 'open': false });
  };

  function renameKey(obj, oldKey, newKey) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  };

  async function fetchEdaRuns() {
    setLoading(true)
    const response = await handleApiCall(`${baseURL}edaRuns`, 'get', {}, navigate, myContext)
    setLoading(false)
    if (response?.status === 200) {
      var edaRuns = response.data;
      edaRuns.forEach(obj => {
        renameKey(obj, 'run_id', 'id')
        obj.created_at = moment(obj.created_at).format("YYYY/MM/DD kk:mm:ss");
      });
      setRowData(edaRuns);
    } else {
      setAlert({ "open": true, "message": response?.data?.message });
    }
  }

  useEffect(() => {
    // Show status message of newly create EDA run
    if (statusMessage && statusMessage?.status_message && statusMessage?.status_message !== "") {
      setAlert({ "open": true, "message": state.status_message, severity: "success" })
    }

    fetchEdaRuns()
  }, [rows]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value)
    setFormValues({
      ...formValues,
      [name]: {
        ...formValues[name],
        value
      }
    })
  }

  const handleFileChange = (e) => {
    if (!e.target.files) {
      return;
    }

    setFile(e.target.files[0]);
  };


  const runValidations = () => {
    const formFields = Object.keys(formValues);
    let newFormValues = { ...formValues }
    console.log(formFields, newFormValues)
    var error_count = 0
    if (formValues.run_name.value === '') {
      newFormValues = {
        ...newFormValues,
        "run_name": {
          ...newFormValues["run_name"],
          error: true
        }
      }
      error_count++
    }

    if (!file && formValues.source_url.value === '') {
      newFormValues = {
        ...newFormValues,
        "file": {
          ...newFormValues["file"],
          error: true
        },
        "source_url": {
          ...newFormValues["source_url"],
          error: true
        }
      }
      error_count++
    } else {
      newFormValues = {
        ...newFormValues,
        "file": {
          ...newFormValues["file"],
          error: false
        },
        "source_url": {
          ...newFormValues["source_url"],
          error: false
        }
      }
    }
    setFormValues(newFormValues)
    console.log("new values has been set")
    return error_count
  }

  const createEDARun = async () => {
    const error_count = runValidations()
    if (error_count) return
    if (file && file.size === 0) {
      setAlert({ "open": true, "message": "File size is 0 kb. Please upload a valid file.", severity: "error" })
      return
    }
    setLoading(true)
    let formData = new FormData();
    formData.append("file", file);
    formData.append("run_name", formValues.run_name.value);
    formData.append("data_source", formValues.source_url.value);
    formData.append("start_eda_run", true);
    let headers = {
      'Content-Type': 'multipart/form-data'
    }

    try {
      const response = await handleApiCall(`${baseURL}createEDARun`, 'post', formData, navigate, myContext, headers)
      if (response?.status == 200) {
        console.log(response);
        let existingRows = rowData
        var result = [response, ...existingRows]
        setLoading(false)
        // Generate new Alert
        setAlert({ "open": true, "message": "New EDA Run successfully created." });
        // Make Empty all the fields
        const formFields = Object.keys(formValues);
        let newFormValues = { ...formValues }
        for (let index = 0; index < formFields.length; index++) {
          const currentField = formFields[index];
          newFormValues = {
            ...newFormValues,
            [currentField]: {
              ...newFormValues[currentField],
              value: "",
              error: false
            }
          }
        }
        setFormValues(newFormValues)
        setrows(result)
      } else {
        console.log("ELSE ERROR###", response)
        setLoading(false)
        setAlert({ "open": true, "message": response?.data?.message });
      }
    } catch (error) {
      console.log("CATCH ERROR###", error.response)
      setLoading(false)
      setAlert({ 'open': true, 'message': error?.response?.data?.message || "Some error occured", severity: 'error' })
    }
  };

  const prepareEDARun = async () => {
    const error_count = runValidations()
    if (error_count) return
    setLoading(true)
    console.log("inside handlePrepareEDA", formValues);
    let formData = new FormData();
    formData.append("run_name", formValues.run_name.value);
    if (file)
      formData.append("file", file);
    else
      formData.append("data_source", formValues.source_url.value);
    formData.append("start_eda_run", false);
    let headers = {
      'Content-Type': 'multipart/form-data'
    }
    try {
      const response = await handleApiCall(`${baseURL}createEDARun`, 'post', formData, navigate, myContext, headers)
      setLoading(false)
      if (response?.status === 200) {
        myContext.setRunId(response.data.run_id);
        myContext.setRunName(formValues.run_name.value);
        myContext.setDataSource(formValues.source_url.value)
        navigate('/data-source');
      } else {
        console.log(response)
        setAlert({ "open": true, "message": response?.data?.message });
      }
    } catch (error) {
      setLoading(false)
      setAlert({ 'open': true, 'message': error.response.data.message, severity: 'error' })
    }
  };

  return (
    <div>
      <Helmet>
        <title>Dashboard</title>
        <meta name="description" content="Description of Dashboard" />
      </Helmet>
      <Snackbar className=" mt-20" anchorOrigin={{ vertical: "top", horizontal: "center" }} open={alert.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alert.severity} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
      {loading ? <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop> : ""}

      <Box className="container-bg bg-contain  py-5 md:px-[15%] px-[5%]">
        <Card elevation={4} className="2xl:container  mt-10  py-2" style={{ borderRadius: 10, border: '1px', paddingLeft: "4%", paddingRight: "4%" }}>
          <Grid container spacing={2} className="flex flex-row">
            <Grid container spacing={0} xs={12} md={12} className="p-4">
              <Grid item xs={12} className="bg-white font-bold text-2xl py-3" style={{ color: PRIMARY_COLOR }}> Create EDA </Grid>
              <Grid item xs={12}>
                <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white" for="file_input">1. Run Name</label>
              </Grid>
              <Grid item xs={5.5}>
                <TextField
                  id="name"
                  label="Run Name"
                  name="run_name"
                  variant="outlined"
                  value={formValues.run_name.value}
                  type="text"
                  required
                  error={formValues.run_name.error}
                  helperText={formValues.run_name.error && formValues.run_name.errorMessage}
                  onChange={handleChange}
                  size="small"
                  style={{ width: "100%" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <Tooltip className="" title="Provide a name for EDA" placement="bottom-start">
                          <IconButton>
                            <BsFillQuestionCircleFill />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                >
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <label class="block mt-4 mb-2 text-sm font-medium text-gray-900 dark:text-white" for="file_input">2. Data Source</label>
              </Grid>
              <Grid item xs={5.5}>
                <TextField
                  id="file"
                  name="file"
                  variant="outlined"
                  type="file"
                  size="small"
                  inputProps={{ accept: ".csv, .json" }}
                  style={{ width: "100%" }}
                  className="cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  onChange={handleFileChange}
                  // value={formValues.source_url.value}
                  error={formValues.file.error}
                  helperText={formValues.file.error && formValues.file.errorMessage}
                />
              </Grid>
              <Grid item xs={1} className="flex justify-center items-center">
                <p>OR</p>
              </Grid>
              <Grid item xs={5.5}>
                <TextField
                  id="bigquery url"
                  label="BigQuery URL"
                  name="source_url"
                  variant="outlined"
                  value={formValues.source_url.value}
                  error={formValues.source_url.error}
                  helperText={formValues.source_url.error && formValues.source_url.errorMessage}
                  type="text"
                  required
                  onChange={handleChange}
                  size="small"
                  style={{ width: "100%" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <Tooltip className="" title="Provide a BigQuery dataset url i.e. bq://project_name.dataset_name.table_name" placement="bottom-start">
                          <IconButton>
                            <BsFillQuestionCircleFill />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <label class="block mt-4 text-sm font-medium text-gray-900 dark:text-white" for="file_input">3. EDA Type</label>
              </Grid>
              <Grid container spacing={0} xs={12} md={12} className=" text-center content-center p-2" >
                <Grid item xs={5.5} className="">
                  <Tooltip title="Create a New EDA Run after processing data using cleansing and transformation techniques" placement="right">
                    <Button variant="contained" disableElevation color="primary" onClick={prepareEDARun} className="w-full" style={{ background: "#4285f4", color: "white", textTransform: "none" }}>
                      <span className="w-full"> Create EDA (with Data Prep) </span>
                    </Button>
                  </Tooltip>
                  <NavLink to="/data-source">
                  </NavLink>
                </Grid>
                <Grid item xs={1} className="" ></Grid>
                <Grid item xs={5.5} className="" >
                  <Tooltip title="Create a New EDA Run on Raw Data" placement="right">
                    <Button variant="contained" color="#4285f4" onClick={createEDARun} className="w-full" style={{ background: "#4285f4", color: "white", textTransform: "none" }}>
                      Create EDA (on Raw Data)
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </Card>
      </Box >
      <Box className="flex flex-col mb-4 md:px-[15%] px-[5%]">
        <Card elevation={4} className="2xl:container  mt-2  py-2" style={{ borderRadius: 10, border: '1px', paddingLeft: "4%", paddingRight: "4%" }}>
          <h1 className="font-bold my-3  text-2xl" style={{ color: PRIMARY_COLOR }}> EDA History
            <Tooltip className="" title="Run Name is a New EDA run name, used to identify different created EDA runs" placement="bottom-end">
              <IconButton>
                <BsFillQuestionCircleFill />
              </IconButton>
            </Tooltip>
          </h1>

          <Box elevation={0} className="py-5 pt-5  w-full">
            <DataTable rows={rowData.filter(row => row.status === "running" || row.status === "completed")} />
          </Box>
        </Card>
      </Box>
    </div >
  );
}

export default Dashboard;


// DataTable Component
function DataTable(props) {

  console.log("PROPS", props)
  const myContext = useContext(AppContext);
  const navigate = useNavigate();

  const downloadPDF = (run_id) => {
    console.log("RUN ID is", run_id)
    myContext.setLoading(true)

    const res = EdaAPI.downloadPDF(run_id);
    res.then(response => {
      const file = new Blob([response.data], { type: 'application/pdf' });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      window.open(fileURL);
      myContext.setLoading(false)
    })
  }

  const downloadProcessedData = (run_id) => {
    console.log("RUN ID is", run_id)
    myContext.setLoading(true)

    const res = EdaAPI.downloadProcessedData(run_id, navigate, myContext);
    res.then(response => {
      const file = new Blob([response.data], { type: 'application/csv' });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      window.open(fileURL);
      myContext.setLoading(false)

    })
  }

  const columns = [
    { field: "id", headerName: "ID", width: 70, hide: true },
    { field: "run_name", headerName: "Run Name", width: 180 },
    { field: "data_source", headerName: "Source", width: 360 },
    {
      field: "status",
      headerName: "Status",
      width: 180,
    },
    {
      field: "created_at",
      headerName: "Created",
      width: 180
    },
    {
      field: "EDA Report",
      sortable: false,
      width: 180,
      renderCell: (params) => {
        let currentRowData = props.rows.filter(row => row.id == params.row.id)
        console.log(currentRowData, "currentRowData check")
        return (
          <Button
            variant="contained"
            color="#4285f4"
            onClick={() => downloadPDF(params.row.id)}
            disabled={currentRowData && currentRowData[0] && currentRowData[0].status === "running" ? true : false}
            style={{
              background: currentRowData && currentRowData[0] && currentRowData[0].status === "running" ? "" : "#4285f4", color: "white", textTransform: "none"
            }
            }
          >
            Download Report
          </Button >
        );
      },
    },
    {
      field: "EDA Data",
      sortable: false,
      width: 180,
      renderCell: (params) => {
        let currentRowData = props.rows.filter(row => row.id == params.row.id)
        console.log(currentRowData, "currentRowData check")
        return (
          <Button
            variant="contained"
            color="#4285f4"
            onClick={() => downloadProcessedData(params.row.id)}
            disabled={currentRowData && currentRowData[0] && currentRowData[0].status === "running" ? true : false}
            style={{
              background: currentRowData && currentRowData[0] && currentRowData[0].status === "running" ? "" : "#4285f4", color: "white", textTransform: "none"
            }
            }
          >
            Download Data
          </Button >
        );
      },
    }
  ];

  return (
    <div className="" style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={props.rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection={false}
        disableColumnSelector={true}
        loading={!props.rows}
      />
    </div>
  );
}