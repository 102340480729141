/**
 *
 * DataSource
 *
 */

import React, { memo, useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import AppContext from '../../components/AppContext/AppContext';

import {
  Box,
  Button,
  TextField,
  Card,
  MenuItem,
  FormControl,
  FormControlLabel,
  Dialog
} from "@material-ui/core";
import "./transformationPage.css";
import { HiArrowCircleLeft } from 'react-icons/hi';
import { NavLink, useNavigate } from "react-router-dom";
import HorizontalLabelPositionBelowStepper from "../../components/HorizontalStepperComponent";
import { DataGrid } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from '@mui/material/CircularProgress';
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import { PRIMARY_COLOR } from "../../constants";
import { Tooltip } from "@mui/material";
import { BsFillQuestionCircleFill } from 'react-icons/bs'
import IconButton from '@mui/material/IconButton';
import { handleApiCall } from "../../utils/request"

const baseURL = process.env.REACT_APP_API_HOST;


export function TransformationPage() {
  const navigate = useNavigate();
  const myContext = useContext(AppContext);
  console.log("my context....", myContext)
  const run_id = myContext.run_id;
  const data_source = myContext.data_source;
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (run_id === "") {
      // || data_source === ""
      console.log("values are empty")
      navigate('/dashboard');
    }
    getTableDataFromLocalStorage();
    return () => { };
  }, []);


  const handleFormDataChange = (newFormData) => {
    console.log("Updated state in parent", newFormData);
    if (newFormData) {
      setRows(newFormData);
      console.log(rows, "state has been updated in parent")
    }
  };

  const getTableDataFromLocalStorage = () => {
    console.log("inside getTableDataFromLocalStorage")
    const _rowData = myContext.selected_features
    console.log(_rowData, "_rowData check")
    let inputRows = _rowData.filter(row => row.is_input == true)
    console.log(_rowData, "_rowData check")

    setRows(inputRows)
  };

  const updateTranformationLocalStorage = async () => {
    console.log("inside updateDataSourceLocalStorage");
    myContext.setSelectedFeatures(rows)

    const payload = { "feature_details": rows }
    try {
      const response = await handleApiCall(`${baseURL}edaRun/${run_id}`, 'put', payload, navigate, myContext)
      console.log(response);
      if (response?.status == 200) {
        console.log("saved intermediate data")
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ padding: '0% 15%' }}>
      <Helmet>
        <title>Dashboard</title>
        <meta name="description" content="Description of Dashboard" />
      </Helmet>
      <Box className="container-bg bg-contain md:p-0 p-5">
        <Box className="2xl:container m-auto py-5 md:pb-5 md:p-2 pt-5 md:pt-8">
          <Box className="flex flex-row items-center justify-center mb-8">
            <NavLink to="/data-cleansing" onClick={(event) => { updateTranformationLocalStorage() }}>
              <span><HiArrowCircleLeft style={{ width: '66px', height: '66px', color: PRIMARY_COLOR }} /></span>
            </NavLink>
            <HorizontalLabelPositionBelowStepper activeStp={2} />
            <NavLink to="/eda-run" onClick={(event) => { updateTranformationLocalStorage() }}>
              <span><HiArrowCircleLeft className="flip-image" style={{ width: '66px', height: '66px', color: PRIMARY_COLOR }} /></span>
            </NavLink>
          </Box>
          <Box className="container-bg bg-contain md:p-0 p-5">
            <Box className="2xl:container m-auto md:pb-5">
              <Card elevation={4} style={{ borderRadius: 10 }} className=" w-full">
                {rows.length ? <DataTransformationTable rows={rows} onChangeRowData={handleFormDataChange} /> : ""}
              </Card>
            </Box>
          </Box>
          <Box className="flex flex-row items-center justify-center pr-20">
            <Box className=" mt-4 pr-20 flex flex-row items-center">
              <NavLink to="/data-cleansing">
                <Button variant="contained" disableElevation style={{ backgroundColor: PRIMARY_COLOR, color: "white" }} onClick={(event) => { updateTranformationLocalStorage() }}>
                  <span className="w-full py-1 px-5">Back</span>
                </Button>
              </NavLink>
            </Box>

            <Box className=" mt-4 ">


              <NavLink to="/eda-run">
                <Button variant="contained" disableElevation style={{ backgroundColor: PRIMARY_COLOR, color: "white" }} onClick={(event) => { updateTranformationLocalStorage() }}>
                  <span className="w-full py-1 px-5">Next </span>
                </Button>
              </NavLink>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default TransformationPage;

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "8rem",
  },

  textField2: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(3),

    width: "8rem",
  },

  dropdown: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(6),
    width: "8rem",
  },

  textFieldLarge: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(6),
    width: "-webkit-fill-available",
  },
  container2: {
    maxHeight: 440,
  },
  formControl: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(6),
    width: "-webkit-fill-available",
  },
  formControl2: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(6),
    width: "-webkit-fill-available",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

function DataTransformationTable(props) {
  const classes = useStyles();
  const myContext = useContext(AppContext);
  const run_id = myContext.run_id
  const navigate = useNavigate();

  console.log("PROPS DATA....", props)
  const [page, setPage] = useState(0);
  const [selectedRowData, setSelectedRowData] = React.useState([]);
  const [currentRow, setCurrentRow] = useState(null);
  const [activeChart, setActiveChart] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [openSettings, setOpenSettings] = React.useState(false);
  const [errors, setErrors] = React.useState(
    {
      normalization_strategy:'', 
      normalization_range:'',
      normalization_range_min:'',
      normalization_range_max:'',
      bucket_type:'', 
      bucket_ranges:''

    }
  )
  

  const [timeStampList, setTimeStampList] = useState([
    {
      name: "Extract Year",
      value: "extract_year",
      checked: false,
    },
    {
      name: "Extract Month",
      value: "extract_month",
      checked: false,
    },
    {
      name: "Extract Date",
      value: "extract_date",
      checked: false,
    },
    {
      name: "Extract Day of Week",
      value: "extract_day_of_week",
      checked: false,
    },
    {
      name: "Extract Hour",
      value: "extract_hour",
      checked: false,
    },
    {
      name: "Extract Minutes",
      value: "extract_minutes",
      checked: false,
    },
  ]);

  const handleStrategyChange = (e, id) => {
    console.log(id, "check handleStrategyChange ", e);
    if(e.target.value!=='')
    {
      setErrors({...errors, normalization_strategy:''})
    }
    let selectedRow = props.rows.filter(row => row.id == id)[0]
    console.log(selectedRow, "selectedRow before testing")
    let newFormData = props.rows.filter(row => row.id != id)
    if (e.target.value === "log_scaling" || e.target.value === "z_score") {
      selectedRow.normalization_range_min = null
      selectedRow.normalization_range_max = null
    }
    selectedRow.normalization_strategy = e.target.value
    console.log(selectedRow, "selectedRow after testing")
    newFormData.push(selectedRow)
    newFormData.sort((a, b) => (a.id > b.id) ? 1 : -1)

    props.onChangeRowData(newFormData)
    console.log(props.rows, "formData finally")
  };

  const handleNormalizationRange = (e, id, param) => {
    if(e.target.value!=='')
    {
      setErrors({...errors, [param]:''})
    }
    let selectedRow = props.rows.filter((row) => row.id === id)[0];
    let newFormData = props.rows.filter((row) => row.id != id);
    if (param === "normalization_range_min")
      selectedRow['normalization_range_min'] = e.target.value
    else if (param === "normalization_range_max")
      selectedRow['normalization_range_max'] = e.target.value

    newFormData.push(selectedRow);
    newFormData.sort((a, b) => (a.id > b.id ? 1 : -1));

    props.onChangeRowData(newFormData);
    console.log(props.rows, "formData finally strategy");
  };

  const handleBucketingChange = (e, id) => {
    console.log(id, "check handleStrategyChange ", e);
    if(e.target.value!=='')
    {
      setErrors({...errors, bucket_type:''})
    }
    let selectedRow = props.rows.filter(row => row.id == id)[0]
    console.log(selectedRow, "selectedRow before testing")
    let newFormData = props.rows.filter(row => row.id != id)
    selectedRow.bucket_type = e.target.value
    console.log(selectedRow, "selectedRow after testing")
    newFormData.push(selectedRow)
    newFormData.sort((a, b) => (a.id > b.id) ? 1 : -1)
    props.onChangeRowData(newFormData)
    console.log(props.rows, "formData finally")
  };

  const handleBucketingChangeValue = (e, id, param) => {
    console.log(id, "check handleBucketingChangeValue ", e, param);
    if(e.target.value!=='')
    {
      setErrors({...errors, bucket_ranges:''})
    }
    let selectedRow = props.rows.filter(row => row.id == id)[0]
    console.log(selectedRow, "selectedRow before testing")
    let newFormData = props.rows.filter(row => row.id != id)
    selectedRow["bucket_ranges"] = e.target.value
    console.log(selectedRow, "selectedRow after handleBucketingChangeValue")
    newFormData.push(selectedRow)
    newFormData.sort((a, b) => (a.id > b.id) ? 1 : -1)
    props.onChangeRowData(newFormData)
    console.log(props.rows, "formData finally")
  };

  useEffect(() => {
    props.rows.map(row=> {
      let timeStampOptions = []
      let options = JSON.parse(JSON.stringify(timeStampList));
      if (row.fnl_data_type === "date") {
        timeStampOptions = options.filter(t => (t.value !== "extract_hour" && t.value !== "extract_minutes"))
      }
      else if (row.fnl_data_type === "timestamp") {
        timeStampOptions = options.filter(t => (t.value !== "extract_year" && t.value !== "extract_month" && t.value !== "extract_date" && t.value !== "extract_day_of_week"))
      }
      row.timeStampOptions = timeStampOptions;
      return row;
    })
    console.log("updated rows", props.rows)
    generateGraph(page)
  }, []);

  const generateGraph = (newPage) => {
    console.log("generating graph...", newPage, newPage * 5, ((newPage + 1) * 5 - 1))
    setPage(newPage)
    console.log("updated page")
    const data = props.rows.slice(newPage * 5, ((newPage + 1) * 5))
    data.forEach(d => {
      handleApplyChanges(d)
    })
  }

  const handleCurrentRow = (currentRow) => {
    setCurrentRow(currentRow)
    console.log(" handleApplyChanges currentRowData...", currentRow)
  }

  const handleApplyChanges = async (currentRowData, isApplied=false) => {
    currentRowData["chart_loading"] = true
    console.log(currentRowData, "currentRowData check")
    let errors = {}
    // if(!currentRowData.normalization_strategy || currentRowData.normalization_strategy ===''){
    //   errors = ({...errors, normalization_strategy:'Please select a normalization strategy.'})
    //   // return;
    // }

    if(currentRowData.normalization_strategy && currentRowData.normalization_strategy === 'range_scaling' && (!currentRowData.normalization_range_min || currentRowData.normalization_range_min ==='') && (!currentRowData.normalization_range_max || currentRowData.normalization_range_max ==='')){
      errors = {...errors, normalization_range_min:'Please select a minimum normalization range.', normalization_range_max:'Please select a maximum normalization range.'}
      // return;
    }
 
    if(currentRowData.normalization_strategy && currentRowData.normalization_strategy === 'range_scaling' && (!currentRowData.normalization_range_min || currentRowData.normalization_range_min ==='')){
      errors = ({...errors, normalization_range_min:'Please enter minimum normalization range.'})
    }

    if(currentRowData.normalization_strategy && currentRowData.normalization_strategy === 'range_scaling' && (!currentRowData.normalization_range_max || currentRowData.normalization_range_max ==='')){
      errors = ({...errors, normalization_range_max:'Please enter maximum normalization range.'})
    }

    // if(!currentRowData.bucket_type || currentRowData.bucket_type ===''){
    //   errors = ({...errors, bucket_type:'Please select a bucket type.'})
    // }

    if(currentRowData.bucket_type && (!currentRowData.bucket_ranges || currentRowData.bucket_ranges ==='')){
      errors = ({...errors, bucket_ranges:'Please enter bucket range.'})
    }
    if(isApplied && Object.keys(errors).length>0){
      console.log(errors,'all errors..')
      setErrors(errors)
      return;
    }
    setOpenSettings(false)



    const requestObj = {
      "col": currentRowData?.feature_name,
      "run_id": run_id,
      "null_strategy": currentRowData?.null_strategy,
      "null_repl_cstm_val": currentRowData?.null_repl_cstm_val,
      "outlier_strategy": currentRowData?.outlierStrategy,
      "outlier_rep_cstm_val": currentRowData?.outlier_rep_cstm_val,
      "apply_onehot_encoding": currentRowData?.apply_onehot_encoding,
      "sparse_representation_mode": currentRowData?.sparse_representation_mode,
      "list_of_vals": currentRowData?.list_of_vals ? currentRowData?.list_of_vals.split(",") : "",
      "is_normalized": currentRowData?.normalization_strategy ? 1 : 0,
      "normalization_strategy": currentRowData?.normalization_strategy,
      "normalization_range": [parseInt(currentRowData?.normalization_range_min), parseInt(currentRowData?.normalization_range_max)],
      "is_bucketized": currentRowData?.bucket_type ? 1 : 0,
      "bucket_type": currentRowData?.bucket_type,
      "bucket_ranges": currentRowData?.bucket_type == "custom_buckets" ? currentRowData?.bucket_ranges.split(",") : currentRowData?.bucket_ranges,
      "timestamp_transformation": currentRowData?.timestamp_transformation
    }

    // console.log("requestObj", requestObj)
    if (currentRowData?.outlierMethod && currentRowData?.outlierMethod !== "") {
      // console.log("setting outlier method...", currentRowData?.outlierMethod)
      requestObj["outlier_method"] = currentRowData?.outlierMethod
      if (requestObj["outlier_method"] === "date_range")
        requestObj["outlier_strategy"] = "remove"
    }
    if (currentRowData?.outlier_detection_val && currentRowData?.outlier_detection_val !== "") {
      // console.log("getting outlier_detection_val")
      requestObj["outlier_detection_val"] = currentRowData?.outlier_detection_val
    }
    else if (currentRowData?.outlier_detection_val_min !== "" && currentRowData?.outlier_detection_val_max !== "") {
      // console.log("getting outlier_detection_val min nd max")
      requestObj["outlier_detection_val"] = currentRowData?.outlier_detection_val_min + " " + currentRowData?.outlier_detection_val_max
    }

    currentRowData["chart_loading"] = true

    try {
      const response = await handleApiCall(`${baseURL}getTransformedCharts`, 'post', requestObj, navigate, myContext)
      console.log(response);
      // currentRowData["transformed_chart_signed_url"] = response?.data?.signed_url
      currentRowData["chart_loading"] = false
      let selectedRow = props.rows.filter(row => row.id === currentRowData.id)[0]
      // console.log(selectedRow, "selectedRow before testing")
      let newFormData = props.rows.filter(row => row.id != currentRowData.id)
      selectedRow.transformed_chart_signed_url = response?.data?.signed_url
      // console.log(selectedRow, "selectedRow after testing")
      newFormData.push(selectedRow)
      newFormData.sort((a, b) => (a.id > b.id) ? 1 : -1)

      props.onChangeRowData(newFormData)
      console.log(props.rows, "formData finally")
    } catch (error) {
      console.log(error);
      currentRowData["chart_loading"] = true
    }
  }


  const handleSelection = (newSelection) => {
    const selectedRowData = props.rows.filter((row) => row.id === newSelection[0]);
    console.log(selectedRowData, "newSelection check");
    setSelectedRowData(selectedRowData)
    console.log(newSelection, "selectionModel check");
  };

  const handleClose = () => {
    setOpen(false)
    setOpenSettings(false)
    setErrors({
      normalization_strategy:'', 
      normalization_range:'',
      normalization_range_min:'',
      normalization_range_max:'',
      bucket_type:'', 
      bucket_ranges:''

    })
  };

  const toggleOneHotEncoding = (e, id) => {
    console.log(id, "check toggleOneHotEncoding ", e.target.checked);
    let selectedRow = props.rows.filter(row => row.id == id)[0]
    console.log(selectedRow, "selectedRow before testing")
    let newFormData = props.rows.filter(row => row.id != id)
    selectedRow.apply_onehot_encoding = e.target.checked
    console.log(selectedRow, "selectedRow after testing")
    newFormData.push(selectedRow)
    newFormData.sort((a, b) => (a.id > b.id) ? 1 : -1)
    props.onChangeRowData(newFormData)
    console.log(props.rows, "formData finally toggleOneHotEncoding")
  };

  const toggleSparseRepresentation = (e, id) => {
    console.log(id, "check toggleOneHotEncoding ", e.target.checked);
    let selectedRow = props.rows.filter(row => row.id == id)[0]
    console.log(selectedRow, "selectedRow before testing")
    let newFormData = props.rows.filter(row => row.id != id)
    selectedRow.sparse_representation_mode = e.target.checked
    console.log(selectedRow, "selectedRow after testing")
    newFormData.push(selectedRow)
    newFormData.sort((a, b) => (a.id > b.id) ? 1 : -1)
    props.onChangeRowData(newFormData)
    console.log(props.rows, "formData finally")
  };

  const onChangeListOfAcceptableCategories = (e, id) => {
    console.log(id, "check onChangeListOfAcceptableCategories ");
    let selectedRow = props.rows.filter(row => row.id == id)[0]
    console.log(selectedRow, "selectedRow before testing")
    let newFormData = props.rows.filter(row => row.id != id)
    selectedRow.list_of_vals = e.target.value
    console.log(selectedRow, "selectedRow after testing")
    newFormData.push(selectedRow)
    newFormData.sort((a, b) => (a.id > b.id) ? 1 : -1)
    props.onChangeRowData(newFormData)
    console.log(props.rows, "onChangeListOfAcceptableCategories formData finally")
  };

  const columns = [
    // { field: "id", headerName: "ID", width: 170, hide: true },
    { field: "feature_name", headerName: "Feature", flex: 3, minWidth: 140 },
    {
      field: "changeTransformation",
      flex: 5,
      minWidth: 360,
      height: 260,
      headerName: "Transformation / Bucketing",
      // description: 'Transform your data by configuring parameters.',
      renderCell: (params) => {
        let currentRowData = props.rows.filter((row) => row.id === params.row.id)[0];
        console.log("currentRowData timeStampOptions",  currentRowData.timeStampOptions);
        var timeStampOptions = currentRowData.timeStampOptions

        if(params.row.timestamp_transformation){
          timeStampOptions.filter(row => row.value === params.row.timestamp_transformation)[0].checked = true
      }
        return (<>
          {currentRowData?.fnl_data_type === "categorical" ? (
            <div className="flex flex-col px-2 leading-6">
              <Box className="flex flex-row">
                <Box className="flex flex-col  pr-12">
                  <p className="text-gray-400">Apply One Hot Encoding </p>
                  {currentRowData?.apply_onehot_encoding ? "true" : "false"}
                </Box>
                <Box className="flex flex-col">
                  <p className="text-gray-400">Enable Sparse Representation </p>
                  {currentRowData?.sparse_representation_mode ? "true" : "false"}
                </Box>
              </Box>
              {/* <Box className="mt-4">
                <p className="text-gray-400">List of Acceptable Categories</p>
                {currentRowData.list_of_vals ? currentRowData?.list_of_vals : "-"}
              </Box> */}
            </div>
          ) : ""
          }
          {currentRowData?.fnl_data_type === "numerical" ? (
            <Box className="flex flex-col leading-6">
              <Box className="flex flex-row">
                <div className="flex flex-col" style={{ width: "9vw" }}>
                  <p className="text-gray-400"> Normalisation Strategy </p>
                  {/* <label htmlFor="firstName">Normalisation Strategy: </label> */}
                  {currentRowData?.normalization_strategy ? currentRowData?.normalization_strategy : "None"}
                </div>
                {currentRowData && ((currentRowData?.normalization_strategy === "range_scaling")) ? (
                  <div className="flex flex-row px-8">
                    <div className="flex flex-col">
                      <p className="text-gray-400"> Min </p>

                      {currentRowData?.normalization_range_min ? currentRowData?.normalization_range_min : ""}
                    </div>
                    <div className="flex flex-col px-4">
                      <p className="text-gray-400"> Max </p>
                      {currentRowData?.normalization_range_max ? currentRowData?.normalization_range_max : ""}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </Box>
              <Box className="flex flex-row mt-4">
                {/* <label htmlFor="firstName">Normalisation Strategy: </label> */}
                <div className="flex flex-col" style={{ width: "9vw" }}>
                  <p className="text-gray-400"> Bucketing </p>
                  {currentRow?.bucket_type || "None"}
                </div>

                {currentRow && currentRow?.bucket_type === "equal_spaced" ? (
                  <div className="flex flex-col px-8">
                    <p className="text-gray-400"> Value </p>
                    {currentRow?.bucket_ranges}
                  </div>
                ) : (
                  ""
                )}

                {currentRow && currentRow?.bucket_type == "quantile_bucketing" ? (
                  <div className="flex flex-col px-8">
                    <p className="text-gray-400"> Value </p>
                    {currentRow?.bucket_ranges}
                  </div>
                ) : (
                  ""
                )}

                {currentRow && currentRow?.bucket_type == "custom_buckets" ? (
                  <div className="flex flex-col px-8">
                    <p className="text-gray-400"> Value </p>
                    {currentRow?.bucket_ranges}
                  </div>
                ) : (
                  ""
                )}
              </Box>
            </Box>
          ) : ""
          }
          {currentRowData?.fnl_data_type === "date" || currentRowData?.fnl_data_type === "datetime" || currentRowData?.fnl_data_type === "time" || currentRowData?.fnl_data_type === "timestamp" ? (
            <Box className="">
              <Box className="flex flex-col" style={{ lineHeight: "25px" }}>
                {timeStampOptions.map((obj, index) => {
                  return (
                    <div className="">
                      <div className="left-section">
                        <input
                          type="checkbox"
                          id={`custom-checkbox-${index}`}
                          checked={
                            timeStampOptions &&
                            timeStampOptions[index] &&
                            timeStampOptions[index].checked
                          }
                          name={obj.value}
                          // value={obj.value}
                          onChange={(e) => handleTimeStampChange(e, obj.value, currentRowData)}
                        />
                        <label htmlFor={`custom-checkbox-${index}`}>
                          {" "}
                          {obj.name}{" "}
                        </label>
                      </div>
                    </div>
                  );
                })}
              </Box>
            </Box>) : ""
          }
        </>)
      },
    },
    {
      field: " ",
      flex: 1,
      width: 100,
      headerClassName: 'lastcolumnSeparator',
      renderCell: (params) => {
        let currentRowData = props.rows.filter(row => row.id == params.row.id)
        console.log(currentRowData, "currentRowData check apply change")
        return (
          <div>
            {(params.row.fnl_data_type === "date" || params.row.fnl_data_type === "datetime" || params.row.fnl_data_type === "time" || params.row.fnl_data_type === "timestamp") ?
              <Button
                variant="contained"
                onClick={() => { handleCurrentRow(currentRowData[0]); handleApplyChanges(currentRowData[0]) }}
                style={{ width: '50%', margin: "0 auto", backgroundColor: PRIMARY_COLOR, color: "white" }}
              >
                <div> Apply  </div>
              </Button>
              :
              <Button
                variant="contained"
                onClick={() => { handleCurrentRow(currentRowData[0]); setOpenSettings(true) }}
                style={{ width: '50%', margin: "0 auto", backgroundColor: PRIMARY_COLOR, color: "white" }}
              >
                <div> Edit  </div>
              </Button>
            }
            {/* } */}
          </div >
        );
      },
    },
    {
      field: "", headerName: " ", flex: 4, minWidth: 400, height: 350,
      headerClassName: 'lastcolumnSeparator',
      renderCell: (params) => {
        let currentRowData = props.rows.filter((row) => row.id === params.row.id);
        console.log("cuurent chart...", currentRowData)
        if (params.row.fnl_data_type === "time" || params.row.fnl_data_type === "timestamp") {
          // params.row.fnl_data_type === "date" || params.row.fnl_data_type === "datetime" ||
          return (<></>)
        }
        else if (currentRowData[0]["chart_loading"]) {
          return (
            <>
              <Box className="flex flex-col w-full p-5" alignItems="center">
                <CircularProgress size="2rem" />
              </Box>
            </>)
        }
        else if (currentRowData[0].transformed_chart_signed_url) {
          return (
            <>
              <Box className="flex flex-col  m-12 cursor-zoom-in" onClick={() => {
                setActiveChart(currentRowData[0].transformed_chart_signed_url);
                setOpen(true)
              }}>
                <img
                  src={currentRowData[0].transformed_chart_signed_url}
                  width="80%"
                  height="80%"
                  className="align-top"
                  alt="Apply null treatment to see distribution chart"
                />
              </Box>
            </>
          );
        }
        else return (<div className="m-8">Apply null treatment on data and see distribution chart</div>)

      },
    },
  ];


  const handleTimeStampChange = (e, value, data) => {
    handleCurrentRow(data)
    let selectedRow = props.rows.filter(row => row.id === data.id)[0]
    let newFormData = props.rows.filter(row => row.id !== data.id)
    selectedRow.timeStampOptions.forEach(option=> {
      if(option.value === value) option.checked = e.target.checked
      else option.checked = false
    })

    if (e.target.checked) {
      selectedRow.timestamp_transformation = value
    }
    else selectedRow.timestamp_transformation = ""
    newFormData.push(selectedRow)
    newFormData.sort((a, b) => (a.id > b.id) ? 1 : -1)
    props.onChangeRowData(newFormData)
  }

  console.log(selectedRowData, "selectedRowData check")
  return (
    <>
      <div style={{ height: "60vh", width: "100%" }}>

        <Dialog open={open} onClose={handleClose} maxWidth={"md"}>
          <img
            className="image"
            src={activeChart}
            alt="charts"
          />
        </Dialog>
        <Dialog open={openSettings} onClose={handleClose}>
          <div className="mx-8 p-4">
            <div className="flex flex-row mb-8 items-center">
              <h1 className="text-blue-500  text-xl font-bold">Null Treatment</h1>
              <Tooltip title="Apply Transform & Bucketing on the data by configuring parameters." placement="bottom-start">
                <IconButton>
                  <BsFillQuestionCircleFill />
                </IconButton>
              </Tooltip>
            </div>
            <div>
              {currentRow?.fnl_data_type === "categorical" ? (
                <div className=" px-2 ">
                  <Box className="flex flex-row ">
                    <Typography style={{ width: "14vw" }}> Apply One Hot Encoding </Typography>
                    <Box className="mx-10">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              size="small"
                              // checked={checked}
                              onChange={(e) =>
                                toggleOneHotEncoding(e, currentRow?.id)
                              }
                            />
                          }
                        />
                      </FormGroup>
                    </Box>
                  </Box>
                  <Box className="flex flex-row ">
                    <Typography style={{ width: "14vw" }}> Enable Sparse Representation </Typography>
                    <Box className="mx-10">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              size="small"
                              // checked={checked}
                              onChange={(e) =>
                                toggleSparseRepresentation(
                                  e,
                                  currentRow?.id
                                )
                              }
                            />
                          }
                        />
                      </FormGroup>
                    </Box>
                  </Box>
                  {/* <Box style={{ paddingTop: "5px", fontSize: "14px" }}>
                    <Typography color="textSecondary" style={{ fontSize: "14px" }}>
                      {" "}
                      List of Acceptable Categories{" "}
                    </Typography>

                    <Typography>
                      {" "}
                      <TextField
                        id="acceptableCategories"
                        type="text"
                        required
                        className={classes.textField}
                        onChange={(e) =>
                          onChangeListOfAcceptableCategories(
                            e,
                            currentRow?.id
                          )
                        }
                        size="small"
                      />
                    </Typography>
                  </Box> */}
                </div>
              ) : ""
              }
              {currentRow?.fnl_data_type === "numerical" ? (
                <Box className="">
                  <Box className="flex flex-col py-10">
                    <Box className="flex flex-row">
                      <FormControl required>
                        <TextField
                          select
                          labelId="Strategy"
                          label="Normalisation Strategy"
                          variant="outlined"
                          value={currentRow?.normalization_strategy}
                          id="strategy"
                          className={classes.textField}
                          style={{ width: "13vw" }}
                          onChange={(e) =>
                            handleStrategyChange(
                              e,
                              currentRow && currentRow?.id
                            )
                          }
                          size="small"
                          error={errors.normalization_strategy && errors.normalization_strategy!==''}
                          helperText={errors.normalization_strategy!=='' && errors.normalization_strategy}
                        >
                          <MenuItem value=""><em>None</em></MenuItem>
                          <MenuItem value={"range_scaling"}>Scaling to a Range</MenuItem>
                          <MenuItem value={"log_scaling"}> Log Scaling </MenuItem>
                          <MenuItem value={"z_score"}> Z-Score </MenuItem>
                        </TextField>
                      </FormControl>
                      {currentRow && ((currentRow?.normalization_strategy === "range_scaling")) ? (
                        <div className="flex flex-row">
                          <TextField
                            id="value"
                            label="Min"
                            type="text"
                            variant="outlined"
                            required
                            value={currentRow?.normalization_range_min}
                            className={classes.textField}
                            onChange={(e) =>
                              handleNormalizationRange(
                                e,
                                currentRow?.id,
                                "normalization_range_min"
                              )
                            }
                            inputProps={{ style: { fontSize: 12 } }} // font size of input text
                            InputLabelProps={{ style: { fontSize: 12 } }} // font size of input label
                            style={{ width: "6vw" }}
                            size="small"
                            error={errors.normalization_range_min && errors.normalization_range_min!==''}
                            helperText={errors.normalization_range_min!=='' && errors.normalization_range_min}
                          />
                          <TextField
                            id="value"
                            label="Max"
                            type="text"
                            variant="outlined"
                            required
                            value={currentRow?.normalization_range_max}
                            // className={classes.textField}
                            onChange={(e) =>
                              handleNormalizationRange(
                                e,
                                currentRow?.id,
                                "normalization_range_max"
                              )
                            }
                            inputProps={{ style: { fontSize: 12 } }} // font size of input text
                            InputLabelProps={{ style: { fontSize: 12 } }} // font size of input label
                            style={{ width: "6vw" }}
                            size="small"
                            error={errors.normalization_range_max && errors.normalization_range_max!==''}
                            helperText={errors.normalization_range_max!=='' && errors.normalization_range_max}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </Box>
                    <Box className="flex flex-row mt-4">
                      <FormControl required>
                        {/* <InputLabel id="bucketing"> Bucketing </InputLabel> */}
                        <TextField
                          select
                          labelId="Bucketing"
                          label={"Bucketing"}
                          variant="outlined"
                          value={currentRow?.bucket_type}
                          id="bucketing"
                          className={classes.textField}
                          onChange={(e) =>
                            handleBucketingChange(
                              e,
                              currentRow && currentRow?.id
                            )
                          }
                          style={{ width: "13vw" }}
                          size="small"
                          error={errors.bucket_type && errors.bucket_type!==''}
                          helperText={errors.bucket_type!=='' && errors.bucket_type}
                        >
                          <MenuItem value=""><em>None</em></MenuItem>
                          <MenuItem value={"equal_spaced"}>Equal Spaced</MenuItem>
                          <MenuItem value={"quantile_bucketing"}>
                            {" "}
                            Quantile Bucketing{" "}
                          </MenuItem>
                          <MenuItem value={"custom_buckets"}>
                            {" "}
                            Custom Buckets{" "}
                          </MenuItem>
                        </TextField>
                      </FormControl>

                      {currentRow && currentRow?.bucket_type === "equal_spaced" ? (
                        <TextField
                          id="value"
                          label="Value"
                          value={currentRow?.bucket_ranges}
                          type="text"
                          variant="outlined"
                          required
                          className={classes.textField}
                          onChange={(e) =>
                            handleBucketingChangeValue(
                              e,
                              currentRow && currentRow?.id,
                              "equalSpacedValue"
                            )
                          }
                          style={{ width: "12.5vw" }}
                          size="small"
                          error={errors.bucket_ranges && errors.bucket_ranges!==''}
                          helperText={errors.bucket_ranges!=='' && errors.bucket_ranges}
                        />
                      ) : (
                        ""
                      )}

                      {currentRow && currentRow?.bucket_type == "quantile_bucketing" ? (
                        <TextField
                          id="value"
                          label="Value"
                          value={currentRow?.bucket_ranges}
                          variant="outlined"
                          type="text"
                          required
                          className={classes.textField}
                          onChange={(e) =>
                            handleBucketingChangeValue(
                              e,
                              currentRow && currentRow?.id,
                              "quantileBucketingValue"
                            )
                          }
                          style={{ width: "12.5vw" }}
                          size="small"
                          error={errors.bucket_ranges && errors.bucket_ranges!==''}
                          helperText={errors.bucket_ranges!=='' && errors.bucket_ranges}
                        />
                      ) : (
                        ""
                      )}

                      {currentRow && currentRow?.bucket_type == "custom_buckets" ? (
                        <TextField
                          id="value"
                          label="Value"
                          value={currentRow?.bucket_ranges}
                          type="text"
                          variant="outlined"
                          required
                          className={classes.textField}
                          onChange={(e) =>
                            handleBucketingChangeValue(
                              e,
                              currentRow && currentRow?.id,
                              "customBucketingValue"
                            )
                          }
                          style={{ width: "12.5vw" }}
                          size="small"
                          error={errors.bucket_ranges && errors.bucket_ranges!==''}
                          helperText={errors.bucket_ranges!=='' && errors.bucket_ranges}
                        />
                      ) : (
                        ""
                      )}
                    </Box>
                  </Box>
                </Box>

              ) : ""
              }
            </div>
            <div className="text-center mt-8"><Button
              variant="contained"
              onClick={() => { handleApplyChanges(currentRow, true) }}
              style={{ backgroundColor: PRIMARY_COLOR, color: "white" }}
            >
              <div> Apply  </div>
            </Button></div>
          </div>
        </Dialog>
        <DataGrid
          rowHeight={160}
          rows={props.rows}
          columns={columns}
          checkboxSelection={false}
          pageSize={5}
          rowsPerPageOptions={[5]}
          onSelectionModelChange={(newSelection) => {
            handleSelection(newSelection);
          }}
          onPageChange={(newPage) => generateGraph(newPage)}
        />
      </div>
    </>
  );
}