
export function setToken(myContext, access_token, refresh_token, user_id) {
    myContext.setsigned_in(true);
    sessionStorage.setItem('access_token', access_token);
    sessionStorage.setItem('refresh_token', refresh_token);
    sessionStorage.setItem('user_id', user_id);
    sessionStorage.setItem('signed_in', true);
}

export function getAccessToken() {
    const tokenString = sessionStorage.getItem('access_token');
    return tokenString
}

export function getRefreshToken() {
    const tokenString = sessionStorage.getItem('refresh_token');
    return tokenString
}


export function logout(myContext) {
    myContext.setsigned_in(false);
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    sessionStorage.setItem('signed_in', false);
}

