/**
 *
 * DataSource
 *
 */

import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import AppContext from '../../components/AppContext/AppContext';
import { NavLink, useNavigate } from "react-router-dom";
import { Box, Button, Grid } from "@material-ui/core";
import "./edaPage.css";
import { HiArrowCircleLeft } from 'react-icons/hi';
import HorizontalLabelPositionBelowStepper from "../../components/HorizontalStepperComponent";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { PRIMARY_COLOR } from "../../constants";
import { handleApiCall } from "../../utils/request"

const baseURL = process.env.REACT_APP_API_HOST;

export function EdaPage() {
  const navigate = useNavigate();
  const myContext = useContext(AppContext);
  const [loading, setLoading] = useState(false)

  const run_id = myContext.run_id;

  const selected_features = myContext.selected_features
  console.log("Selected Feature", selected_features)
  const feature_details = selected_features.map(f => {
    const temp = {}
    temp["feature_sl_no"] = f.feature_sl_no
    temp["feature_name"] = f.feature_name
    temp["fnl_data_type"] = f.fnl_data_type
    temp["is_input"] = f.is_input
    temp["is_output"] = f.is_output ? f.is_output : 0
    temp["null_strategy"] = f.null_strategy ? f.null_strategy : ""
    temp["null_repl_cstm_val"] = f.null_repl_cstm_val ? f.null_repl_cstm_val : ""
    temp["outlier_method"] = f.outlier_method ? f.outlier_method : ""
    temp["outlier_detection_val"] = f.outlier_detection_val ? f.outlier_detection_val : ""
    temp["outlier_strategy"] = f.outlier_strategy ? f.outlier_strategy : ""
    temp["outlier_rep_cstm_val"] = f.outlier_rep_cstm_val ? f.outlier_rep_cstm_val : ""
    temp["apply_onehot_encoding"] = f.apply_onehot_encoding ? f.apply_onehot_encoding : 0
    temp["sparse_representation_mode"] = f.sparse_representation_mode ? f.sparse_representation_mode : 0
    temp["list_of_vals"] = f.list_of_vals ? f.list_of_vals.split(",") : ""
    temp["is_normalized"] = f.normalization_strategy ? 1 : 0
    temp["normalization_strategy"] = f.normalization_strategy ? f.normalization_strategy : ""
    temp["normalization_range"] = f.normalization_range ? f.normalization_range : null
    temp["is_bucketized"] = f.bucket_type ? 1 : 0
    temp["bucket_type"] = f.bucket_type ? f.bucket_type : ""
    temp["bucket_ranges"] = f.bucket_ranges ? f.bucket_ranges : ""
    temp["timestamp_transformation"] = ""
    console.log("TEMP...", temp)
    return temp
  })

  console.log("FEATURES DETAILS...", feature_details)

  const onStartEDARun = async () => {
    console.log(selected_features)
    setLoading(true)
    const requestObj = {
      "run_id": run_id,
      "feature_details": feature_details
    }

    try {
      const response = await handleApiCall(`${baseURL}triggerEDARun`, 'post', requestObj, navigate, myContext)
      console.log(response);
      setLoading(false)
      myContext.clearContext()
      navigate('/dashboard', { state: { status_message: "'" + myContext.run_name + "' EDA has been created successfully" } });
    } catch (error) {
      console.log(error);
    }

  };

  return (
    <div style={{ padding: '0% 15%' }}>
      <Helmet>
        <title>Dashboard</title>
        <meta name="description" content="Description of Dashboard" />
      </Helmet>
      {loading ? <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop> : ""}
      <Box className="container-bg bg-contain md:p-0 p-5">
        <Box className="2xl:container m-auto   py-5 md:pb-5 md:p-2  pt-5 md:pt-8">
          <Box className="flex flex-row items-center justify-center mb-8">
            <NavLink to="/data-tranformation">
              <span><HiArrowCircleLeft style={{ width: '66px', height: '66px', color: PRIMARY_COLOR }} /></span>
            </NavLink>
            <HorizontalLabelPositionBelowStepper activeStp={3} />
          </Box>
          <Box className="container-bg bg-contain md:p-0 p-5">
            <Box className="2xl:container m-auto   py-5 md:pb-5 md:p-20 md:px-20 pt-5 md:pt-20"></Box>
          </Box>

          <div>
            <Grid container className="flex flex-row justify-center">
              <span className="p-5" style={{}}>
                <a href="/dashboard"> cancel </a>
              </span>
              <Button size="large" type="submit" onClick={onStartEDARun} style={{ background: "#4285f4", color: "white", textTransform: "none" }}>
                Start EDA run
              </Button>
            </Grid>
          </div>
        </Box>
      </Box>
    </div>
  );
}

export default EdaPage;
