/**
 *
 * Signup
 *
 */

import React, { useState, useEffect, useContext } from "react";
import AppContext from '../../components/AppContext/AppContext';
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import {
  Box,
  Button,
  TextField,
} from "@material-ui/core";
import "./style.css";
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import NewLogo from '../../assets/images/pp_logo.png'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { BsFillQuestionCircleFill } from 'react-icons/bs'

import Backdrop from '@mui/material/Backdrop';
import axios from 'axios';
import { PRIMARY_COLOR } from "../../constants";
import { Card } from "@material-ui/core";
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useSearchParams } from 'react-router-dom'
// import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";


// const Alert = React.forwardRef(function Alert(props, ref) {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });
const baseURL = process.env.REACT_APP_API_HOST;
const baseauthURL = process.env.REACT_APP_AUTH_HOST;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export function VerifyEmail() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const myContext = useContext(AppContext);
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = React.useState({ 'open': false, 'message': '' });
  const [searchParams, setSearchParams] = useSearchParams();


  const verify_email_confirmation_token = async () => {
    setLoading(true);
    const token = searchParams.get("token")

    const user_id = sessionStorage.getItem('user_id');
    axios.post(`${baseauthURL}confirm_email?token=${token}`)
      .then(function (response) {
        if (response?.status === 200 && response?.data?.message == "Email verifed") {
          setLoading(false)
          navigate('/login', { state: { loginMsg: 'You email has been confirmed!!' } })
        }
        else {
          setLoading(false)
          setAlert({ 'open': true, 'message': response?.data?.message, severity: 'error' })
        }
      })
      .catch(function (error) {
        setLoading(false)
        setAlert({ 'open': true, 'message': error, severity: 'error' })
      });

  }

  useEffect(() => {
    verify_email_confirmation_token();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlert({ 'open': false, 'message': '' });
  };

  return (
    <div>
      <Snackbar className=" mt-20" anchorOrigin={{ vertical: "top", horizontal: "center" }} open={alert.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alert.severity} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>

      {loading ? <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop> : ""}
      <Box className="flex flex-col mb-4 md:px-[30%] px-[5%] py-[6%] justify-center items-center place-content-center self-center selfce">
        <Card elevation={4} className="2xl:container  mt-2  py-8" style={{ borderRadius: 10, border: '1px', paddingLeft: "4%", paddingRight: "4%", alignContent: "center" }}>
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >

            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <BsFillQuestionCircleFill />
            </Avatar>
            <Box component="form" noValidate sx={{ mt: 3 }}>
              <Grid container className="mt-2" justifyContent="flex-end">
                <Grid item>
                  <Link href="login" variant="body2">
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>

          {/* </h1> */}
        </Card>
      </Box>
    </div >
  );
}

export default VerifyEmail;