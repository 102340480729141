/**
 *
 * Login
 *
 */

import React, { useState, useEffect, useContext } from "react";
import AppContext from '../../components/AppContext/AppContext';
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import {
  Box,
  Button,
  TextField,
} from "@material-ui/core";
import "./style.css";
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { BsFillQuestionCircleFill } from 'react-icons/bs'
import { Card } from "@material-ui/core";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const baseURL = process.env.REACT_APP_API_HOST;
const baseauthURL = process.env.REACT_APP_AUTH_HOST;

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const myContext = useContext(AppContext);
  const [formValues, setFormValues] = useState({
    run_name: {
      value: '',
      error: false,
      errorMessage: 'You must enter a run name'
    },
    source_url: {
      value: '',
      error: false,
      errorMessage: 'You must enter a source url'
    }
  })
  const [alert, setAlert] = React.useState({ 'open': false, 'message': '' });
  useEffect(() => {
    if (sessionStorage.getItem("signed_in") === "true") {
      navigate('/dashboard');
    }

    // Show status message of newly create EDA run

    // axios.get(`${baseURL}edaRuns`)
    //   .then((response) => {
    //     console.log("INSIDE RESPONSE")
    //     var edaRuns = response.data;
    //     // const arr = JSON.parse(edaRuns)
    //     edaRuns.forEach(obj => {
    //       renameKey(obj, 'run_id', 'id')
    //       obj.created_at = moment(obj.created_at).format("YYYY/MM/DD kk:mm:ss");
    //     });
    //     console.log("EDA RUNS", edaRuns)
    //     setRowData(edaRuns);
    //     console.log("ROWS ", rows)
    //   });
    // return () => { };
  }, []);

  const runValidations = () => {
    const formFields = Object.keys(formValues);
    let newFormValues = { ...formValues }
    console.log(formFields, newFormValues)
    var error_count = 0
    for (let index = 0; index < formFields.length; index++) {
      const currentField = formFields[index];
      const currentValue = formValues[currentField].value;

      if (currentValue === '') {
        newFormValues = {
          ...newFormValues,
          [currentField]: {
            ...newFormValues[currentField],
            error: true
          }
        }
        error_count++
      } else {
        newFormValues = {
          ...newFormValues,
          [currentField]: {
            ...newFormValues[currentField],
            error: false
          }
        }
      }
    }
    console.log(newFormValues)
    setFormValues(newFormValues)
    console.log("new values has been set")
    return error_count
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlert({ 'open': false, 'message': '' });
  };

  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email')
    });

    axios.post(`${baseauthURL}reset-password`, data)
      .then(function (response) {
        console.log(response);
        if (response?.status == 200) {
          setLoading(false);
          setAlert({ 'open': true, 'message': response?.data?.message, severity: 'success' })
        } else {
          setLoading(false)
          setAlert({ 'open': true, 'message': response?.data?.message, severity: 'error' })
        }
      })
      .catch(function (error) {
        setAlert({ 'open': true, 'message': error.response.data.message || "Some error occured", severity: 'error' })
        setLoading(false);
        console.log(error);
      });

  };

  return (
    <div>
      <Snackbar className=" mt-20" anchorOrigin={{ vertical: "top", horizontal: "center" }} open={alert.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alert.severity} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
      {loading ? <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop> : ""}
      <Box className="flex flex-col mb-4 md:px-[35%] px-[5%] py-[6%] justify-center items-center place-content-center self-center selfce">
        <Card elevation={4} className="2xl:container  mt-2  py-8" style={{ borderRadius: 10, border: '1px', paddingLeft: "4%", paddingRight: "4%", alignContent: "center" }}>
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <BsFillQuestionCircleFill />
            </Avatar>
            <Typography component="h1" variant="h5">
              Forgot Your Password?
            </Typography>

            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <div className=" text-sm text-zinc-400">Enter your email address below and we'll send you a link to reset your password.
              </div>
              <TextField
                margin="normal"
                required
                fullWidth
                variant="outlined"
                size="small"
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{ background: "#4285f4", color: "white", textTransform: "none", marginTop: "12px" }}
                sx={{ mt: 3, mb: 2 }}
              >
                Send Reset Link
              </Button>
              <Grid className="mt-2" container >

                <Grid item>
                  <Link href="login" variant="body2" >
                    {"Already have an account? Sign in"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Card>
      </Box>
    </div >
  );
}

export default ForgotPassword;