import AppContext from './components/AppContext/AppContext';
import './App.css';
import {
  Box
} from "@material-ui/core";

import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Backdrop, CircularProgress } from '@mui/material';

import TopNavBarComponent from "./components/TopNavBarComponent";
import Footer from "./components/Footer/Footer";
import { Home } from './containers/Home';
import { Dashboard } from './containers/Dashboard';
import DataSource from './containers/DataSource';
import CleansingPage from './containers/CleansingPage';
import TransformationPage from './containers/TransformationPage';
import EdaPage from './containers/EdaPage';
import Login from './containers/Login';
import Subscription from './containers/Subscription';
import { Signup } from './containers/Signup';
import Profile from './containers/Profile';
import ForgotPassword from './containers/ForgotPassword';
import ResetPassword from './containers/ResetPassword';
import VerifyEmail from './containers/VerifyEmail';

function App() {
  const [loading, setLoading] = useState(false);
  const [run_name, setRunName] = useState('');
  const [run_id, setRunId] = useState('');
  const [data_source, setDataSource] = useState('');
  const [selected_features, setSelectedFeatures] = useState([]);
  const [source_details, setSourceDetails] = useState({});
  const [signed_in, setsigned_in] = useState(Boolean(sessionStorage.getItem('signed_in')));

  const clearContext = () => {
    setRunName("");
    setRunId("")
    setDataSource("")
    setSelectedFeatures([])
    setSourceDetails({})
    console.log('CLEARED All the context details'); // not cleared
  };

  const edaConfigs = {
    run_name: run_name,
    run_id: run_id,
    data_source: data_source,
    selected_features: selected_features,
    source_details: source_details,
    isLoading: loading,
    signed_in: signed_in,
    setLoading,
    setRunName,
    setRunId,
    setDataSource,
    setSelectedFeatures,
    setSourceDetails,
    clearContext,
    setsigned_in
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return <div>{loading ?
    (<div style={{ display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
    ) : ("")}
    <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <AppContext.Provider value={edaConfigs}>
        <Router>
          <Box className="bg-white">
            <TopNavBarComponent />
          </Box>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            {/* <Navigate replace to={signed_in ? "/dashboard" : "/login"} /> */}
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/subscriptions" element={<Subscription />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/data-source" element={<DataSource />} />
            <Route path="/data-cleansing" element={<CleansingPage />} />
            <Route path="/data-tranformation" element={<TransformationPage />} />
            <Route path="/eda-run" element={<EdaPage />} />
          </Routes>
        </Router>
        <Box className="bg-white mt-auto">
          <Footer />
        </Box>
      </AppContext.Provider>
    </div>
  </div>;
}

export default App;