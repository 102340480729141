import React from "react";
import {
  Box,
  makeStyles,
} from "@material-ui/core";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import './HorizontalStepperComponent.css';

const steps = ["Source", "Cleansing", "Transformation", "EDA"];

export default function HorizontalLabelPositionBelowStepper(activeStp) {
  console.log("check step ", activeStp);
  const useStyles = makeStyles({
    customLabelStyle: {
      fontSize: "18px",
    },
  });

  const classes = useStyles();
  return (
    <Box sx={{ width: "50%" }} className="m-auto">
      <Stepper activeStep={activeStp.activeStp} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel classes={{ label: classes.customLabelStyle }}   >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
