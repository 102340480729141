/**
 *
 * Login
 *
 */

import React, { useState, useEffect, useContext } from "react";
import AppContext from '../../components/AppContext/AppContext';
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import GoogleIcon from "../../assets/images/google_icon.png"
import {
  Box,
  Button,
  TextField,
} from "@material-ui/core";
import "./style.css";
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { BsFillQuestionCircleFill } from 'react-icons/bs'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import axios from 'axios';
import { Card } from "@material-ui/core"
import pecanprep1 from "../../assets/images/product/pecanprep-1.png";
import { gapi } from "gapi-script";
import GoogleLogin from 'react-google-login';

import Snackbar from '@mui/material/Snackbar';

import { setToken, getAccessToken, getRefreshToken } from "../../utils/token";
import MuiAlert from '@mui/material/Alert';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const baseURL = process.env.REACT_APP_API_HOST;
const baseauthURL = process.env.REACT_APP_AUTH_HOST;

export function Login() {
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (state && state?.loginMsg) {
      setAlert({ 'open': true, 'message': state?.loginMsg, 'severity': 'success' });
    }
  }, [])

  const myContext = useContext(AppContext);
  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const [error, setError] = useState(false)
  const [contactOpen, setContactOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    email: {
      value: '',
      error: false,
      errorMessage: 'You must enter an email'
    },
    password: {
      value: '',
      error: false,
      errorMessage: 'You must enter a password'
    }
  })
  const [selectedImage, setSelectedImage] = useState(pecanprep1)

  const [alert, setAlert] = React.useState({ 'open': false, 'message': '' });

  useEffect(() => {
    if (myContext.signed_in !== true) {
      function start() {
        gapi.client.init({
          clientId:
            "979819894352-1msptmrk39g3ku53sgetfdvch6i6endr.apps.googleusercontent.com",
          scope: ""
          // ,redirect_URI: ""
        });
      }
      gapi.load("client: auth2", start);
    }
  }, []);

  useEffect(() => {
    if (myContext.signed_in === true) {
      navigate('/dashboard');
    }
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlert({ 'open': false, 'message': '' });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value)
    setFormValues({
      ...formValues,
      [name]: {
        ...formValues[name],
        value
      }
    })
  }

  const runValidations = () => {
    const formFields = Object.keys(formValues);
    let newFormValues = { ...formValues }
    console.log(formFields, newFormValues)
    var error_count = 0
    for (let index = 0; index < formFields.length; index++) {
      const currentField = formFields[index];
      console.log(" currentField", currentField)
      const currentValue = formValues[currentField].value;
      console.log("current value", currentValue)
      if (!currentValue || currentValue === '') {
        console.log("currentField empty", currentField)
        newFormValues = {
          ...newFormValues,
          [currentField]: {
            ...newFormValues[currentField],
            error: true,
          }
        }
        error_count++
      } else {
        newFormValues = {
          ...newFormValues,
          [currentField]: {
            ...newFormValues[currentField],
            error: false
          }
        }
      }
    }

    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (!pattern.test(formValues.email.value)) {
      newFormValues = {
        ...newFormValues,
        'email': {
          ...newFormValues['email'],
          error: true,
          errorMessage: "Please enter valid email address."
        }
      }
      error_count++
    }
    console.log(newFormValues)
    setFormValues(newFormValues)
    console.log("new values has been set")
    return error_count
  }


  const handleSubmit = (event) => {
    event.preventDefault();
    const error_count = runValidations()
    if (error_count) return
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
    setLoading(true)
    axios.post(`${baseauthURL}login`, data)
      .then(function (response) {
        setLoading(false)
        console.log(response);
        if (response?.data?.status_code === 200) {
          setToken(myContext, response['data']['access_token'], response['data']['refresh_token'], response['data']['user_id']);
          navigate('/dashboard');
        }
        else {
          window.scrollTo(0, 0)
          setAlert({ 'open': true, 'message': response?.data?.message, severity: 'error' })
        }
      })
      .catch(function (error) {
        window.scrollTo(0, 0)
        setLoading(false);
        setAlert({ 'open': true, 'message': error, severity: 'error' })
      });
  };
  const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  const handleShow = (title) => {
    setShow(true)
    // setPopupTitle(title)
    // setPopupDetails(serviceDetails[title])
  };
  const responseGoogle = async (response) => {
    setLoading(true)
    if (response?.profileObj) {
      const verifiedUserResponse = await axios.post(`${baseauthURL}google-login`, response);
      if (verifiedUserResponse?.status === 200) {
        console.log(verifiedUserResponse)
        setLoading(false)
        setToken(myContext, verifiedUserResponse['data']['access_token'], verifiedUserResponse['data']['refresh_token'], verifiedUserResponse['data']['user_id']);
        navigate('/dashboard');

      } else {
        setAlert({ 'open': true, 'message': verifiedUserResponse?.data?.message, severity: 'error' })
      }
    }
    else {
      setAlert({ 'open': true, 'message': "Can't able to login please retry.", severity: 'error' })
    }
  }

  return (
    <div>
      <Helmet>
        <title>Login</title>
        <meta name="description" content="Description of Login Page" />
      </Helmet>
      {loading ? <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop> : ""}

      <Snackbar className=" mt-20" anchorOrigin={{ vertical: "top", horizontal: "center" }} open={alert.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alert.severity} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
      <Box className="flex flex-col mb-4 md:px-[30%] px-[30%] py-[6%] justify-center items-center place-content-center self-center selfce">
        <Card elevation={4} className="2xl:container  mt-2  py-8" style={{ borderRadius: 10, border: '1px', paddingLeft: "4%", paddingRight: "4%", alignContent: "center" }}>
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <BsFillQuestionCircleFill />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                variant="outlined"
                size="small"
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={handleChange}
                value={formValues.email.value}
                error={formValues.email.error}
                helperText={formValues.email.error && formValues.email.errorMessage}
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                variant="outlined"
                size="small"
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handleChange}
                value={formValues?.password?.value}
                error={formValues?.password?.error}
                helperText={formValues?.password?.error && formValues?.password?.errorMessage}
              />
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{ background: "#4285f4", color: "white", textTransform: "none", marginTop: "12px" }}
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid className="mt-2" container>
                <Grid item xs>
                  <Link href="forgot-password" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="signup" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
              <GoogleLogin
                // clientId="1055239772833-5noli2goh9liuvn3acoslv1a5dfqnu8k.apps.googleusercontent.com"
                clientId="979819894352-1msptmrk39g3ku53sgetfdvch6i6endr.apps.googleusercontent.com"
                render={renderProps => (
                  <Button
                    style={{ width: "100%", borderRadius: "4px", padding: "6px 0px" }}
                    onClick={renderProps.onClick}
                  >
                    <Button
                      fullWidth
                      variant="contained"
                      style={{ background: "#4285f4", color: "white", textTransform: "none", marginTop: "12px" }}
                      sx={{ mt: 3, mb: 2 }}
                      className="google_button"
                      display="flex"
                      justifyContent="start"
                    // style={{width:"100%", marginTop: "10px", borderRadius:"4px", fontSize: "13px", lineHeight: "52px"}}
                    >
                      <Box sx={{ height: "40px", width: "40px", background: "white", borderRadius: "4px" }}>
                        <img src={GoogleIcon} alt="google_icon" width="100%" style={{ marginTop: "6px" }} />
                      </Box>
                      <Box display="flex" justifyContent="center" className="innerTextGoogleBtn">
                        Sign In With Google
                      </Box>
                    </Button>
                  </Button>
                )}
                onSuccess={responseGoogle}
                onFailure={(response) => console.log(response)}
              />
              {/* <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{ background: "#4285f4", color: "white", textTransform: "none", marginTop: "12px" }}
                sx={{ mt: 3, mb: 2 }}
              // onClick={()=> navigate(baseauthURL + "login-google-outh")}
              // onClick={handleGoogleLogin}
              >
                <a href={baseauthURL + "login-google-outh"} rel="noopener noreferrer">

                  <img src={GoogleIcon} alt="google-icon" width="23px" style={{ marginRight: "10px" }} />
                  Sign in with Google
                  </a>
              </Button> */}

            </Box>
          </Box>

        </Card>
      </Box>
    </div >
  );
}

export default Login;