import axios from 'axios';
import { getAccessToken, getRefreshToken, logout } from "../utils/token"

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */

function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.data;
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  console.log('parse json', response);
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(path, options) {
  const requestUrl =
    path.indexOf('http://') > -1 || path.indexOf('https://') > -1
      ? path
      : process.env.API_URL + path;
  if (!options) options = {};
  options.url = requestUrl;
  let headers = options.headers || {};
  headers['Content-Type'] = 'application/json';
  // const token = authentication.getToken();
  // console.log(options.headers, headers);
  // if (token && !headers['Authorization']) {
  //   headers['Authorization'] = `Token ${token}`;
  // }
  let data = options.data;
  for (let key in data) {
    if (data[key] === '' || data[key] === undefined || data[key] === null) {
      delete data[key];
    }
  }
  options.data = data;
  options.headers = headers;
  console.log('axios .....request', path, ' = = ', options);
  return axios(options)
    .then(checkStatus)
    .then(parseJSON);
}

const handleApiCall = async (url, method, data, navigate, myContext, headers = {}) => {

  const baseauthURL = process.env.REACT_APP_AUTH_HOST;
  const refreshToken = getRefreshToken()
  let authorization = getAccessToken()
  let response;
  try {
    if (method == 'get') {
      data["headers"] = { authorization: `Bearer ${authorization}` }
      response = await axios.get(url, data)
    } else if (method == 'post') {
      headers["authorization"] = `Bearer ${authorization}`
      response = await axios.post(url, data, { headers: headers })
    } else if (method == "put") {
      headers["authorization"] = `Bearer ${authorization}`
      response = await axios.put(url, data, { headers: headers })
    }
    return response;

  } catch (response) {
    try {
      const responseRefreshToken = await axios.post(`${baseauthURL}refresh`, {}, { headers: { authorization: `Bearer ${refreshToken}` } })
      sessionStorage.setItem('access_token', responseRefreshToken?.data?.access_token);
      authorization = responseRefreshToken?.data?.access_token
    } catch (error) {
      logout(myContext);
      sessionStorage.clear()
      navigate('/login', { state: { loginMsg: 'Please login to access the application' } });
    }

    // try{
    if (method == 'get') {
      headers["authorization"] = `Bearer ${authorization}`
      response = await axios.get(url, { headers: headers })
    } else if (method == 'post') {
      headers["authorization"] = `Bearer ${authorization}`
      response = await axios.post(url, data, { headers: headers })
    } else if (method == "put") {
      headers["authorization"] = `Bearer ${authorization}`
      response = await axios.put(url, data, { headers: headers })
    }
    return response;
    // } catch (error){
    //   console.log(error)
    //   return error
    // }

  }
}

export { handleApiCall };