/**
 *
 * TopNavBarComponent
 *
 */

import * as React from 'react';
import NewLogo from '../../assets/images/pp_logo.png'

import { useState, useEffect, useContext } from "react";
import './style.css';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ContactForm from "../../containers/ContactForm";

import { useLocation, useNavigate } from 'react-router-dom';
import { logout } from "../../utils/token";
import axios from 'axios';
import AppContext from '../../components/AppContext/AppContext';
import { handleApiCall } from "../../utils/request"

const pages = ['Dashboard', 'Subscriptions'];
const settings = ['Profile', 'Logout'];
const baseURL = process.env.REACT_APP_API_HOST;

const TopNavBarComponent = () => {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [contactOpen, setContactOpen] = useState(false);
  const [alert, setAlert] = React.useState({ 'open': false, 'message': '' });
  const [userDetails, setUserDetails] = React.useState({});
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    console.log("inside anchorElUser")
    console.log(userDetails.name)

    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (page) => {
    setAnchorElNav(null);
    setLoading(false);
    navigate(page);
  };

  const get_user_details = async () => {
    // setLoading(true);
    const user_id = sessionStorage.getItem('user_id');
    const response = await handleApiCall(`${baseURL}user-profile/${user_id}`, 'get')
    if (response?.status === 200) {
      setUserDetails(response?.data);
    }
    else {
      // setLoading(false)
      setAlert({ 'open': true, 'message': response?.data?.message, severity: 'error' })
    }
  }

  useEffect(() => {
    setAnchorElUser(false);
    get_user_details()
  }, []);
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleContactClose = (open) => {
    setContactOpen(false);

  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const myContext = useContext(AppContext);

  const handleClose = (page) => {
    setLoading(true);
    if (page == "profile") {
      setLoading(false);
      navigate('/profile');
    }
    if (page == "logout") {
      const baseAuthURL = process.env.REACT_APP_AUTH_HOST;
      axios.get(`${baseAuthURL}logout`)
        .then((response) => {
          logout(myContext);
          setLoading(false);
          sessionStorage.clear()
          navigate('/login');
          // const arr = JSON.parse(edaRuns)
        });
    }
    setAnchorEl(null);
  };

  return (
    <div className="bg-white ">
      {loading ? <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop> : ""}
      <ContactForm open={contactOpen} handleModalClose={handleContactClose} />

      <AppBar position="static" style={{
        background: "orange"
      }}>
        <Container >
          <Toolbar className='flex justify-between' disableGutters>
            <img
              src={NewLogo}
              width="6%"
              height="6%"
              className="align-top mr-14 cursor-pointer"
              alt="Finsharp logo"
              onClick={() => navigate("/")}
            />
            {/* For xs scree size */}
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }} className="flex justify-end">
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {myContext.signed_in === true ?
                  (pages.map((page) => (
                    <MenuItem key={page} onClick={() => handleCloseNavMenu(page.toLowerCase())}>
                      <Typography textAlign="center">{page}</Typography>
                    </MenuItem>
                  ))) :
                  (<MenuItem key={"Subscriptions"} onClick={() => handleCloseNavMenu("subscriptions")}>
                    <Typography textAlign="center">Subscriptions</Typography>
                  </MenuItem>)}
              </Menu>
            </Box>
            {myContext.signed_in === true ? 
            <Box  sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} className="flex justify-end ml-16">
              {(pages.map((page) => (
                    <MenuItem className="mx-2" key={page} onClick={() => handleCloseNavMenu(page.toLowerCase())}>
                      <Typography textAlign="center">{page}</Typography>
                    </MenuItem>
                  )))}
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ pl: 2 }}>
                  <Avatar alt={userDetails?.name} src={userDetails?.image} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={() => handleClose(setting.toLowerCase())}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box> : 
            <Box sx={{  display: { xs: 'none', md: 'flex' } }} >
              <Button variant="outlined" style={{ borderRadius: "6px", margin: "12px" }} onClick={() => navigate('/subscriptions')}>Subscriptions</Button>
              <Button variant="outlined" style={{ borderRadius: "6px", margin: "12px" }} onClick={() => navigate('/login')}>Login</Button>
              <Button variant="outlined" style={{ borderRadius: "6px", margin: "12px" }} onClick={() => navigate('/signup')}>Try for free</Button>
            </Box>
            }
          </Toolbar>
        </Container>
      </AppBar>
    </div >
  );
}

TopNavBarComponent.propTypes = {};
export default TopNavBarComponent;